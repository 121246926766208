import * as React from "react";
import ThisIsEnoughSection from "./ThisIsEnoughSection";
import LiveWidgetSection from "./LiveWidgetSection";
import TestReportRecoverSection from "./TestReportRecoverSection";
import ReportNowSection from "./ReportNowSection";
import StatsSection from "./StatsSection";
import ReportingToPoliceSection from "./ReportingToPoliceSection";
import PageContainer from "./PageContainer";

export default function HomePage() {
  return (
    <PageContainer>
      <ThisIsEnoughSection />
      <TestReportRecoverSection />
      <StatsSection />
      <ReportingToPoliceSection />
      <LiveWidgetSection />
      <ReportNowSection />
    </PageContainer>
  );
}
