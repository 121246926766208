import React, { useContext } from "react";
import { SizeContext } from "./App";
import { videoDataContext } from "./VideoDataProvider";
import { VideoData } from "../api";

import { Box } from "@mui/material";
import { Link } from "react-router-dom";

interface VideoThumbnailProps {
  video: VideoData;
}

export default function YouTubeThumbnailList() {
  const size = useContext(SizeContext);
  const large = size === "large";
  function VideoThumbnail(props: VideoThumbnailProps) {
    return (
      <Box sx={{ display: "inline-block", pr: 2 }}>
        <Link
          to={`https://www.youtube.com/watch?v=${props.video.id}`}
          target="_blank"
        >
          <img
            src={
              large
                ? props.video.thumbnail_url_large
                : props.video.thumbnail_url_small
            }
          />
        </Link>
      </Box>
    );
  }

  const videoData = useContext(videoDataContext);

  return videoData.length ? (
    <Box sx={{ overflow: "auto", whiteSpace: "nowrap" }}>
      {videoData.map((v: VideoData, i: number) => (
        <VideoThumbnail video={v} key={i} />
      ))}
    </Box>
  ) : (
    <></>
  );
}
