import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import { Box, Stack } from "@mui/material";

export default function AlliesAdviceSection() {
  return (
    <PaddedBox>
      <Stack gap={8}>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Understand the reality of rape
          </Typography>
          <br />
          <Typography variant="body2">
            One of the most important things to do is to know what they are
            talking about, and understand the complexities they face. It is
            never as simple as ‘it happened, so I should report to the police.’
            We’ve outlined what you should know about rape below.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Create space for the conversation
          </Typography>
          <br />
          <Typography variant="body2">
            This could be the most awful thing that has ever happened to them.
            Respect the importance of this conversation by giving it the privacy
            and time it needs. In that moment, nothing else matters.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Affirm what they have been through
          </Typography>
          <br />
          <Typography variant="body2">
            Don’t jump to conclusions or actions – that can come later. You
            simply need to affirm and bear witness to what they have been
            through. It may be hard for them to acknowledge, but affirming that
            this was not their fault is a powerful and vital message.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Limit your questions
          </Typography>
          <br />
          <Typography variant="body2">
            They will share what they want to. If you do need to ask a question,
            only do it when they are truly finished speaking, consider the
            phrasing (so as not to imply doubt) and make it clear they don’t
            need to answer. Think whether the question is helpful for them or
            because of your own curiosity.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Don’t assume a role
          </Typography>
          <br />
          <Typography variant="body2">
            Try to separate the structure and hierarchy of your relationship
            with them from what they are telling you and ask how they want you
            to be involved instead of assuming based on your relationship and
            previous situations.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            Don’t try to solve it now
          </Typography>
          <br />
          <Typography variant="body2">
            Prepare for your life to be disrupted a bit. See this a bit like a
            natural disaster – buildings can be knocked down in an instant, and
            take years to rebuild. This is a journey and it will be a more
            restorative one through your involvement, but is definitely not one
            to rush. You are listening to hear rather than listening to solve or
            advise. A non- judgmental ear can be a pivotal part of their
            recovery.
          </Typography>
        </Box>
      </Stack>
    </PaddedBox>
  );
}
