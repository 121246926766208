import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import Copyright from "./Copyright";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

export default function Footer() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ py: 6 }}>
      <Stack
        gap={3}
        className="hide-print"
        component="section"
        direction={large ? "row" : "column"}
        justifyContent={large ? "space-around" : "center"}
        alignItems={large ? "flex-end" : "center"}
      >
        <Stack direction="row" spacing={2} justifyContent="center">
          <Link to={Links.Facebook} target="_blank">
            <FacebookIcon color="secondary" />
          </Link>
          <Link to={Links.Twitter} target="_blank">
            <TwitterIcon color="secondary" />
          </Link>
          <Link to={Links.Instagram} target="_blank">
            <InstagramIcon color="secondary" />
          </Link>
        </Stack>

        <Stack direction="row">
          <Stack sx={{ mr: 6 }}>
            <Link style={{ textDecoration: "none" }} to={Links.Approach}>
              <Typography variant="caption" fontSize="0.75rem">
                Recovery Approach
              </Typography>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to={Links.Youtube}
              target="_blank"
            >
              <Typography variant="caption" fontSize="0.75rem">
                Recovery Videos
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Allies}>
              <Typography variant="caption" fontSize="0.75rem">
                Guides for allies
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Social}>
              <Typography variant="caption" fontSize="0.75rem">
                Enough Community
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Therapy}>
              <Typography variant="caption" fontSize="0.75rem">
                Therapy
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Contact}>
              <Typography variant="caption" fontSize="0.75rem">
                Contact
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={Links.Donate}>
              <Typography variant="caption" fontSize="0.75rem">
                Donate
              </Typography>
            </Link>
          </Stack>
          <Stack>
            <Link style={{ textDecoration: "none" }} to={Links.Next}>
              <Typography variant="caption" fontSize="0.75rem">
                Next Steps
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Crisis}>
              <Typography variant="caption" fontSize="0.75rem">
                Crisis Information
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.FAQ}>
              <Typography variant="caption" fontSize="0.75rem">
                FAQs
              </Typography>
            </Link>

            <Link style={{ textDecoration: "none" }} to={Links.Instructions}>
              <Typography variant="caption" fontSize="0.75rem">
                Kit Instructions
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={Links.Privacy}>
              <Typography variant="caption" fontSize="0.75rem">
                Privacy Policy
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={Links.Terms}>
              <Typography variant="caption" fontSize="0.75rem">
                Terms and Conditions
              </Typography>
            </Link>
          </Stack>
        </Stack>
        <br />
      </Stack>
      <Box display="flex" justifyContent="center" sx={{ mt: 2, w: "100%" }}>
        <Copyright />
      </Box>
    </PaddedBox>
  );
}
