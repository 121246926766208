import React, { useContext } from "react";
import { SizeContext } from "./App";
import { Box, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import vagina from "../img/test_vagina.png";
import vulva from "../img/test_vulva.png";
import anus from "../img/test_anus.png";
import lips from "../img/test_lips.png";
import skin from "../img/test_skin.png";
import PaddedBox from "./PaddedBox";

export default function InstructionsSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ width: large ? "75%" : "100%" }}>
      <Grid2 container alignItems="center">
        <Grid2 xs={large ? 7 : 12}>
          <Stack gap={3} sx={{ p: large ? 3 : 0 }}>
            <Typography variant="caption" fontFamily="Avenir Extra Bold">
              Vagina (internal)
            </Typography>
            <Typography variant="caption">
              Test within: 7 days, ideally 48h.
            </Typography>

            <Typography variant="caption">
              Appropriate for: penetrative sex (either penis or other body
              part). Even if there was a condom or no ejaculation, this is the
              best place to take the sample.
            </Typography>

            <Typography variant="caption">
              Insert swab 3-5cm inside vagina. Gently rotate and try to avoid
              vaginal wall on entry/exit.
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={large ? 5 : 12}>
          <Box
            sx={{ border: "solid 2px black", maxWidth: "100%" }}
            component="img"
            src={vagina}
          />
        </Grid2>
      </Grid2>
      <br />
      <Grid2 container alignItems="center">
        <Grid2 xs={large ? 7 : 12}>
          <Stack gap={3} sx={{ p: large ? 3 : 0 }}>
            <Typography variant="caption" fontFamily="Avenir Extra Bold">
              Vulva (external)
            </Typography>
            <Typography variant="caption">
              Test within: 7 days, ideally 48h.
            </Typography>

            <Typography variant="caption">
              Appropriate for: no penetration
            </Typography>

            <Typography variant="caption">
              Roll one swab over the vulva and perineum.
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={large ? 5 : 12}>
          <Box
            sx={{ border: "solid 2px black", maxWidth: "100%" }}
            component="img"
            src={vulva}
          />
        </Grid2>
      </Grid2>
      <br />
      <Grid2 container alignItems="center">
        <Grid2 xs={large ? 7 : 12}>
          <Stack gap={3} sx={{ p: large ? 3 : 0 }}>
            <Typography variant="caption" fontFamily="Avenir Extra Bold">
              Anus
            </Typography>
            <Typography variant="caption">Test within: 3 days.</Typography>

            <Typography variant="caption">
              Appropriate for: anal penetrative sex (either penis or other body
              part), even if a condom was used.
            </Typography>

            <Typography variant="caption">
              With a little bit of pressure, roll the swab in a circle over the
              perianal skin, 3cm from the anus.
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={large ? 5 : 12}>
          <Box
            sx={{ border: "solid 2px black", maxWidth: "100%" }}
            component="img"
            src={anus}
          />
        </Grid2>
      </Grid2>
      <br />
      <Grid2 container alignItems="center">
        <Grid2 xs={large ? 7 : 12}>
          <Stack gap={3} sx={{ p: large ? 3 : 0 }}>
            <Typography variant="caption" fontFamily="Avenir Extra Bold">
              Mouth
            </Typography>
            <Typography variant="caption">Test within: 2 days</Typography>

            <Typography variant="caption">
              Appropriate for: oral sex (either penis or other body part), even
              if a condom was used.
            </Typography>

            <Typography variant="caption">
              With a little bit of pressure, roll the swab over the outer
              (darker) part of the lips, including where the lips meet.
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={large ? 5 : 12}>
          <Box
            sx={{ border: "solid 2px black", maxWidth: "100%" }}
            component="img"
            src={lips}
          />
        </Grid2>
      </Grid2>
      <br />
      <Grid2 container alignItems="center">
        <Grid2 xs={large ? 7 : 12}>
          <Stack gap={3} sx={{ p: large ? 3 : 0 }}>
            <Typography variant="caption" fontFamily="Avenir Extra Bold">
              Skin
            </Typography>
            <Typography variant="caption">Test within: 2 days</Typography>

            <Typography variant="caption">
              Appropriate for: prolonged skin to skin contact e.g. persistent
              rubbing
            </Typography>

            <Typography variant="caption">
              Roll a swab over the skin area with some pressure. Focus area
              might include breasts (especially mouth contact), pubic bone,
              inner thigh, groin crease, buttocks, penis and scrotum
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={large ? 5 : 12}>
          <Box
            sx={{
              border: "solid 2px black",
              maxWidth: "100%",
            }}
            component="img"
            src={skin}
          />
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
