import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import PaddedBox from "./PaddedBox";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PageContainer from "./PageContainer";

export default function BristolPage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PageContainer>
      <PaddedBox sx={{ pb: 3 }}>
        <Typography variant="h3">Collect a kit from a safe space</Typography>
      </PaddedBox>
      <PaddedBox orange>
        <Grid2 container>
          <Grid2 xs={large ? 6 : 12} sx={{ pb: large ? 0 : 4 }}>
            <Typography variant="h3" color="white">
              Cafes
            </Typography>
            <Typography variant="body1" color="white">
              <ul>
                <li>Boston Tea Party, Park St</li>
                <li>Burra, Redland</li>
                <li>Gather Coffee, Part St</li>
                <li>Interlude Coffee, St Michaels</li>
                <li>Society Cafe, Farr’s Lane</li>
              </ul>
            </Typography>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Typography variant="h3" color="white">
              Pubs/restaurants
            </Typography>
            <Typography variant="body1" color="white">
              <ul>
                <li>Brewdog, Baldwin</li>
                <li>Ciderpress, Gloucester Rd</li>
                <li>Hope & Anchor, Jacob Wells Rd</li>
                <li>Jersey Lily, Redland</li>
                <li>Left Handed Giant, Redcliffe</li>
                <li>Nandos, Park St</li>
                <li>The Canteen, Stokes Croft</li>
                <li>The Gallimaufry, Gloucester Rd</li>
                <li>White Harte, Park Row</li>
              </ul>
            </Typography>
          </Grid2>
        </Grid2>
      </PaddedBox>
      <PaddedBox>
        <Typography variant="h3">Access additional services</Typography>
        <br />
        <Typography variant="body1">
          You can collect STI kits or have them delivered straight to you:{" "}
          <Link to="https://www.unitysexualhealth.co.uk">
            https://www.unitysexualhealth.co.uk
          </Link>
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
