import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider } from "react-router-dom";
import { ReportStatsProvider } from "./ReportStatsProvider";
import { ReportsProvider } from "./ReportsProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/en-gb";
import { AuthProvider } from "./AuthProvider";
import Router from "./Router";
import { VideoDataProvider } from "./VideoDataProvider";
import ReactGA from "react-ga4";

export const SizeContext = React.createContext("small");

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GA_MEASUREMENT_ID } = process.env;

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID!);

export default function App() {
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.up("md")) ? "large" : "small";
  return (
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID!}>
      <AuthProvider>
        <SizeContext.Provider value={size}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="en-gb"
          >
            <ReportsProvider>
              <ReportStatsProvider>
                <VideoDataProvider>
                  <RouterProvider router={Router} />
                </VideoDataProvider>
              </ReportStatsProvider>
            </ReportsProvider>
          </LocalizationProvider>
        </SizeContext.Provider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}
