import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";

export default function CrisisSupportSection() {
  return (
    <PaddedBox>
      <Typography variant="h1">What’s crisis support?</Typography>

      <Typography variant="body1">
        Crisis support is there to help you if you need urgent mental health
        support. You can contact crisis lines 24/7 as a one off, or may need
        ongoing support from your local crisis team.
      </Typography>
      <br />

      <Typography variant="subtitle1" sx={{ pb: 1 }}>
        Crisis Line
      </Typography>

      <Typography variant="body1">
        The crisis line is a dedicated telephone line if you need immediate
        support regarding your mental health.{" "}
        <b>
          You should call this number when you feel that you can’t keep yourself
          safe
        </b>
        . Every local area has their own urgent crisis line which can be
        accessed 24 hours a day.
      </Typography>
      <br />
      <Typography variant="subtitle1" sx={{ pb: 1 }}>
        Crisis Team
      </Typography>

      <Typography variant="body1">
        Every area has designated crisis teams who provide support in the
        community for individuals experiencing ongoing mental health crises.
        They might visit you in your home, make regular contact or you may have
        access to a crisis pad (a space separate from A&E to help keep you
        safe.)
      </Typography>

      <Typography variant="body1">
        There are various ways to access support:
      </Typography>

      <Typography variant="body1">
        <ul>
          <li>
            Contact a local crisis team yourself through the NHS link below
          </li>
          <li>
            Be referred into the crisis team by another professional such as
            someone you have seen at A&E, or after a visit to your GP
          </li>
          <li>
            If you are currently under a mental health team they can arrange
            support from the crisis team as part of a safety plan
          </li>
        </ul>
      </Typography>

      <ButtonLink
        theme="outlined"
        href="https://111.nhs.uk/triage/check-your-mental-health-symptoms"
        target="_blank"
      >
        Find my crisis team
      </ButtonLink>
    </PaddedBox>
  );
}
