import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";

export default function CallCrisisLineSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h3" color="common.white">
        If you are experiencing thoughts of suicide or self harm follow this
        link to call your local crisis line
      </Typography>
      <br />

      <Typography variant="h3" color="common.white">
        If you have seriously self-harmed or cannot keep yourself safe, go to
        A&E
      </Typography>
      <br />

      <Typography variant="h3" color="common.white">
        If you feel you are in immediate, life-threatening danger call 999{" "}
      </Typography>
      <br />
      <ButtonLink
        theme="filled"
        href="https://111.nhs.uk/triage/check-your-mental-health-symptoms"
        target="_blank"
      >
        Find my crisis team
      </ButtonLink>
    </PaddedBox>
  );
}
