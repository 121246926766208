import React, { createContext, useState, useEffect } from "react";
import { VideoData, fetchVideos } from "../api";

export const videoDataContext = createContext<VideoData[]>([]);

export const VideoDataProvider = (props: React.PropsWithChildren) => {
  const [videoData, setVideoData] = useState<VideoData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await fetchVideos();
        if (!results) {
          setVideoData([]);
        } else {
          setVideoData(results);
        }
      } catch (e) {
        console.error("Could not fetch video data", e);
      }
    };
    fetchData();
  }, []);

  return (
    <videoDataContext.Provider value={videoData}>
      {props.children}
    </videoDataContext.Provider>
  );
};
