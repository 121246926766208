import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQReportingSection() {
  return (
    <FAQSection title="Reporting">
      <FAQ title="Who does the testing?">
        <Typography variant="body2">
          Our lab partner is UKAS accredited. This means they are one of a few
          recognised by the government to meet a certain standard of excellence.
        </Typography>
      </FAQ>
      <FAQ title="How long do the results take to come?">
        <Typography variant="body2">
          1 working day after the lab receives your sample. Your sample will be
          delivered to the lab overnight as long as you are before the last
          collection.
        </Typography>
      </FAQ>

      <FAQ title="How long do the results take to come?">
        <Typography variant="body2">
          Typically around 2 weeks, but this can vary. The lab stores all kits
          safely before testing.
        </Typography>
      </FAQ>
      <FAQ title="What happens to my sample after testing?">
        <Typography variant="body2">
          We freeze half the sample before testing. This means it can be passed
          on to the police (if you ever want to). After testing the other half,
          we keep it unless you tell us otherwise.
        </Typography>
      </FAQ>
      <FAQ title="How will I get the results?">
        <Typography variant="body2">
          We’ll send you an email to tell you when your results are available on
          your account.
        </Typography>
      </FAQ>
      <FAQ title="Who can see the results?">
        <Typography variant="body2">
          The results belong to you and no one else.
        </Typography>
      </FAQ>
      <FAQ title="If only one DNA profile is found on my sample, does that mean nothing happened?">
        <Typography variant="body2">
          No. There are many reasons why a sample may not show a second profile.
          Over time, DNA naturally degrades, and can be weaker if a form of
          protection was used. The absence of a secondary profile cannot be
          assumed to be evidence that nothing happened.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
