import React from "react";
import Typography from "@mui/material/Typography";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

interface RecoveryStartsSectionProps {
  showLink?: boolean;
}

export default function RecoveryStartsSection(
  props: RecoveryStartsSectionProps
) {
  return (
    <PaddedBox orange>
      <Typography variant="h6" color="common.white">
        Recovery doesn’t mean you’re broken – you are going through a formative
        process with learnings that will help you for the rest of your life.
      </Typography>
      {props.showLink && (
        <>
          <br />
          <ButtonLink
            theme="filled"
            href={Links.About}
            sx={{
              pl: 4,
              mt: 3,
              pr: 4,
            }}
          >
            Learn more
          </ButtonLink>
        </>
      )}
    </PaddedBox>
  );
}
