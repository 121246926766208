import React from "react";
import { Links } from "../types";
import { Box, Dialog, DialogContent, DialogTitle, Link } from "@mui/material";
import ButtonLink from "./ButtonLink";

export interface ReportSavedModalProps {
  open: boolean;
  handleClose: (e: any, reason: string) => void;
}
export default function ReportSavedModal(props: ReportSavedModalProps) {
  const { open, handleClose } = props;
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogContent sx={{ mb: 3 }}>
        <Box
          justifyContent="flex-end"
          display="flex"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <DialogTitle variant="h5">Report Saved</DialogTitle>
          <Link
            variant="button"
            component="button"
            sx={{ p: 2, fontSize: 30, textDecoration: "none" }}
            onClick={() => handleClose(null, "none")}
          >
            ×
          </Link>
        </Box>
        <center>
          <ButtonLink href={Links.Next} theme="filled">
            Next Steps
          </ButtonLink>
        </center>
      </DialogContent>
    </Dialog>
  );
}
