import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import { Link } from "react-router-dom";
import YouTubeVideo from "./YouTubeVideo";

export default function AboutTherapySection() {
  return (
    <PaddedBox sx={{ py: 3 }}>
      <Typography variant="h3">
        If you’d like to understand more about therapy
      </Typography>
      <br />
      <Typography variant="body1">
        We have a dedicated video that explains more about therapy. We recommend
        watching this as a first step. We’ve also laid out the options below:
      </Typography>
      <br />
      <YouTubeVideo src="https://www.youtube.com/embed/a8MLA4FMCJE?si=SEHKAtHmEPwHrf39" />
      <Stack>
        <br />
        <Typography variant="h5">Funded Therapy (free for you)</Typography>
        <br />
        <Typography variant="body1">
          You can access NHS funded therapy by visiting your GP who can make a
          referral to local services in your area.
        </Typography>
        <br />
        <Typography variant="body1">
          Your GP may feel you require a referral to a mental health team who
          can offer more in-depth assessment and support. If you do not feel
          comfortable speaking to your GP you can self-refer into 'Let’s Talk'
          which is an NHS funded psychological therapy service.
        </Typography>
        <br />
        <Typography variant="body1">
          Relate is a psychotherapy service and operates in most areas. They
          accept self-referrals and referrals from the GP. They also specialise
          in relationship and psychosexual therapy if this is something that you
          require support with particularly. They offer both free and funded
          sessions but operate on a sliding scale. This means that you can
          donate what you can afford.
        </Typography>
        <br />
        <Typography variant="body1">
          There are a few things to consider with funded therapy such as long
          waiting lists and limited session numbers, but be assured that if you
          are in crisis you can access support from the crisis teams. Further
          your clinician will assess if you require further support than what
          they are able to offer and will ensure you are signposted to the
          relevant service.
        </Typography>
        <br />
        <Typography variant="h5">
          Private Therapy (you’ll need to pay)
        </Typography>
        <br />
        <Typography variant="body1">
          You can access private therapy from a variety of sources, it can be a
          little overwhelming as there are so many choices and you might not
          always know what you are looking for. We would recommend looking
          through the Counselling Directory:{" "}
          <Link to="https://www.counselling-directory.org.uk">
            www.counselling-directory.org.uk
          </Link>
        </Typography>
        <br />

        <Typography variant="body1">Some key things to consider: </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Ensure you are searching for an accredited counsellor or therapist
              (this will be included in their qualifications & title,
              memberships include NCPS, BACP, HCPC, CORST)
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Ask if the professional if they will offer a short free
              consultation to see if they feel like a good match for you
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Ensure they can offer trauma therapies such as EMDR, Trauma
              Focussed CBT and Narrative Exposure Therapy
            </Typography>
          </li>
        </ul>
      </Stack>
      <br />
      <Stack>
        <Typography variant="h3">
          If you’re already accessing therapy
        </Typography>
        <br />
        <Typography variant="body1">
          If you are currently under any mental health team, or therapy service
          we would advise consulting with your professionals before engaging
          with our resources. We want these videos to be as supportive and
          empowering as possible, but your safety and wellbeing is priority.
          They have been designed so they can be accessed at any time, this
          means you can dip in and out of our resources at a time and pace that
          feels right for you.
        </Typography>
      </Stack>
    </PaddedBox>
  );
}
