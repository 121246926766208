import React, { useContext } from "react";
import { SizeContext } from "./App";
import { Box, Stack, Typography } from "@mui/material";
import { formatFullDate } from "../util";

interface ReportStoryViewProps {
  text: string;
  createdDate: string;
  style: "Story" | "Note";
  sx?: any;
}

export default function ReportStoryView(props: ReportStoryViewProps) {
  const size = useContext(SizeContext);
  const large = size === "large";
  const boxStyle =
    props.style === "Story"
      ? {
          // backgroundColor: "secondary.main",
          backgroundColor: "common.white",
          // color: "common.white",
        }
      : {
          // backgroundColor: "#fffff2",
          // backgroundColor: "floralwhite",
        };

  const { text } = props;
  return (
    <Box
      sx={{
        mb: 4,
        p: 2,
        border: "dotted 1px lightslategray",
        borderRadius: "1rem",
        ...boxStyle,
      }}
    >
      <Typography variant="body1">{text}</Typography>
      <Stack direction="row" justifyContent="right">
        <Typography variant="caption">
          {props.style} created {formatFullDate(props.createdDate)}
        </Typography>
      </Stack>
    </Box>
  );
}
