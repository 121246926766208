import React from "react";
import RecoveryStartsSection from "./RecoveryStartsSection";
import WithEnoughCheckListSection from "./WithEnoughChecklistSection";
import ReadOurApproachSection from "./ReadOurApproachSection";
import PageContainer from "./PageContainer";

export default function RecoveryPage() {
  return (
    <PageContainer>
      <RecoveryStartsSection />
      <WithEnoughCheckListSection />
      <ReadOurApproachSection />
    </PageContainer>
  );
}
