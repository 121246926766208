import React from "react";
import { Box } from "@mui/material";

interface YouTubeVideoProps {
  src: string;
}

export default function YouTubeVideo(props: YouTubeVideoProps) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "block",
        height: 0,
        margin: "auto",
        padding: "0% 0% 56.25%",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          width: "90%",
          height: "90%",
          border: 0,
        }}
        src={props.src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </Box>
  );
}
