import React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";

export default function PrivacyPolicyPage() {
  return (
    <PageContainer>
      <PaddedBox sx={{ pt: 1 }}>
        <Typography variant="body1" sx={{ fontSize: "0.75em !important" }}>
          <p>
            <b>Privacy Policy</b>
          </p>
          <p>
            <b>Effective Date: </b> 18<sup>th</sup> September 2024
          </p>
          <p>
            "Enough" ("we," "our," "us") is committed to protecting and
            respecting your privacy. This Privacy Policy explains how we
            collect, use, and share your personal data in accordance with the
            General Data Protection Regulation (GDPR) and other applicable laws
            in the United Kingdom.
          </p>
          <p>
            By interacting with us, whether by visiting our website, attending
            our events, donating, or otherwise engaging with our work, you agree
            to the terms of this Privacy Policy.
          </p>
          <p>
            <b>1. Who We Are</b>
          </p>
          <p>
            This is enough Limited, trades as "Enough", and has a charity
            registration pending in the UK with the mission to end sexual
            violence by providing support, education, and resources to survivors
            and the public, particularly university students and young adults.
          </p>
          <p>
            <b>2. Data We Collect</b>
          </p>
          <p>
            We may collect and process the following personal data:
            <ul>
              <li>
                Personal Information: Name, contact information (email address,
                phone number, postal address), and date of birth.
              </li>
              <li>
                Financial Information: Payment details when buying kits or
                making a donation.
              </li>
              <li>
                If you submit a DNA sample the testing laboratory will store
                limited data about the test results (including the date of the
                test, the number of DNA profiles detected, and unique reference
                numbers for those DNA profiles) on its own systems. They will
                also store the DNA samples. They will not have your personal
                information
              </li>
              <li>
                Fundraising Preferences: Your interests in our fundraising
                activities, including donation history.
              </li>
              <li>
                Communication Preferences: How you prefer to hear from us (e.g.,
                email, post, or phone).
              </li>
              <li>
                Website Data: Cookies, IP address, browser type, and browsing
                behaviour on our website.
              </li>
              <li>
                Event Participation: Information when you sign up for or attend
                events (e.g., webinars, charity walks).
              </li>
            </ul>
          </p>
          <p>
            <b>3. Your Report and DNA sample</b>
          </p>
          <p>
            <ul>
              <li>
                Our forensic kit meets the requirements to be labelled as
                Forensic DNA Grade. Specifically our swab is produced by
                Sarstedt and is ISO 18385 accredited.
              </li>
              <li>
                Our kits will be self-swabbed, sent via Royal Mail special
                delivery to a UKAS accredited laboratory (ISO 17025).
              </li>
              <li>
                The laboratory will not be able to compare results to those held
                on the UK National DNA Database
              </li>
              <li>
                If you send a completed test, the online reporting form asks you
                for your consent to the use of the DNA test results. The
                reporting form asks you to provide your name and contact
                information and the barcode number of the test kit to allow you
                to access your test results. The laboratory does not receive
                your personal information
              </li>
              <li>
                You must be over the age of 16 to submit a sample for a DNA
                test.
              </li>
              <li>
                <b>
                  <u>
                    You must link your kit to your online account, and complete
                    consent forms for your kit to be tested.
                  </u>
                </b>
              </li>
              <li>
                With your consent, the testing laboratory will store limited
                data about the test results (including the date of the test, the
                number of DNA profiles detected, and unique reference numbers
                for those DNA profiles) on its own systems.
              </li>
              <li>
                There is no possibility or desire for either Enough or the
                laboratory to attribute the samples to an individual person. If
                you choose to name the alleged perpetrator, that will only exist
                in your encrypted private account.
              </li>
              <li>
                In parallel, we will set up an account for you on our secure,
                encrypted platform using the data you provide in the reporting
                form, from which you will be able to access your DNA test
                results. We and the laboratory will have access to the barcode
                number so that we can share your results with you, but neither
                we nor the laboratory will receive anything else you write on
                the reporting form unless you share it with us through another
                channel.
              </li>
              <li>
                <b>
                  <i>
                    The results will be shared via your online account. You will
                    be informed the number and gender of DNA profiles
                  </i>
                </b>
              </li>
              <li>
                The encryption technology we use prevents access or decryption
                of the data. Only you are able to access the data so that if you
                choose to name other individuals in your encrypted account, you
                are the only one who can see that information.
              </li>
              <li>
                We do not receive any DNA samples collected via the DNA
                self-test kit from the laboratory.
              </li>
              <li>
                If, at a later time, you decide to make a report to the police,
                you can download your report from your account and give this
                information to the police.
              </li>
              <li>
                We are not testing for criminal purposes. Nevertheless, our
                laboratory partner is ISO 17025 accredited, with significant
                overlap with the FSP CoP.
              </li>
              <li>
                The laboratory will retain an untested portion of the sample,
                which can be handed over to the police so that they can run
                their own forensic analysis. The portion of the sample that the
                laboratory analyses to give confirmation of the number of
                profiles would not be used by the police.
              </li>

              <li>
                We recommend that individuals who want to report to the police
                or a SARC should go there first because:
                <ul>
                  <li>
                    The results from a self-taken swab will have an impact on
                    results from a subsequent forensic medical examination and
                    will affect interpretation by a forensic scientist.
                  </li>
                  <li>
                    The testing will not be as extensive as at the Police/SARC
                  </li>
                </ul>
              </li>
              <li>
                With advice from the leading forensic and legal experts, we have
                created a self-testing process that is as reliable as possible,
                including a time-stamped testimony and a frozen portion of the
                untested sample, with the shortest chain of custody, and the
                highest chance of being deemed ‘fair’ by a court
              </li>
              <li>
                Courts decide whether evidence will have an adverse effect on
                the fairness of proceedings, and may omit evidence under the
                Police and Criminal Evidence Act, 1984.
              </li>
              <li>
                The laboratory will keep half of the sample (untested) to allow
                the police to be able to run their own analysis. The laboratory
                will not share your results any further unless compelled to do
                so by law.
              </li>

              <li>
                The lab will retain the untested half of your DNA sample for 20
                years, or until you ask us to destroy them.
              </li>
              <li>
                Your test results will be held indefinitely in your Enough
                account, or until you ask us delete them.
              </li>
            </ul>
          </p>
          <p>
            <b>4. How We Use Your Data</b>
          </p>
          <p>
            We use your data for the following purposes:
            <ul>
              <li>
                Fundraising and Donations: Processing donations and managing
                donor relations.
              </li>
              <li>
                Event Management: Registering attendees and providing
                event-related communications.
              </li>
              <li>
                Marketing and Communications: Sending updates about our work,
                fundraising campaigns, and events based on your preferences
                (e.g., email, social media, postal mail).
              </li>
              <li>
                Website Improvement: Analysing website use to improve user
                experience.
              </li>
              <li>
                Compliance and Legal Obligations: Ensuring we comply with
                charity regulations, tax laws, and the GDPR.
              </li>
            </ul>
          </p>
          <p>
            <b>5. Legal Basis for Processing Your Data</b>
          </p>
          <p>
            Under GDPR, we must have a lawful basis for processing your personal
            data. The lawful bases include:
            <ul>
              <li>
                Consent: When you give clear consent to receive marketing
                materials or fundraising communications.
              </li>
              <li>
                Contractual Necessity: To process donations or sign you up for
                events.
              </li>
              <li>
                Legal Obligation: To comply with legal obligations, such as
                financial reporting and auditing.
              </li>
              <li>
                Legitimate Interests: To promote our mission and improve our
                services, provided your rights do not override these interests.
              </li>
            </ul>
          </p>
          <p>
            <b>6. Fundraising and Communications</b>
          </p>
          <p>
            Some of our work is funded by donations, including pilot programs
            and events aimed at ending sexual violence. You may receive
            fundraising appeals if you have opted in or where we have a
            legitimate interest to contact you, such as prior engagement with
            "Enough" or attending one of our events.
          </p>
          <p>
            You can opt out of fundraising communications at any time by
            contacting us at{" "}
            <a href="mailto:hello@myenough.com">hello@myenough.com</a> or using
            the unsubscribe options in our emails.
          </p>
          <p>
            <b>7. Sharing Your Data</b>
          </p>
          <p>
            We may share your data with:
            <ul>
              <li>
                Service Providers: Third-party services for payment processing,
                email distribution, and event management.
              </li>
              <li>
                Regulatory Authorities: To comply with legal obligations, such
                as the Charity Commission or HMRC.
              </li>
              <li>
                Trusted Partners: For joint fundraising activities or event
                co-hosting, where you have consented to such collaboration.
              </li>
            </ul>
          </p>
          <p>
            We will never sell or rent your personal data to third parties for
            marketing purposes.
          </p>
          <p>
            <b>8. Data Retention</b>
          </p>
          <p>
            We retain personal data for as long as is necessary for the purposes
            set out in this Privacy Policy. Donation records are kept as
            required by law and for auditing purposes. We periodically review
            the personal data we hold to ensure it is accurate and up to date.
          </p>
          <p>
            <b>9. Your Data Protection Rights</b>
          </p>
          <p>
            You have the following rights under GDPR:
            <ul>
              <li>Access: Request a copy of your personal data.</li>
              <li>
                You can ask us to instruct the laboratory to delete the data
                from your DNA test kit that they hold, and can withdraw the
                consent you have given at any time.{" "}
              </li>
              <li>
                Rectification: Request correction of inaccurate or incomplete
                data.
              </li>
              <li>
                Erasure: Request deletion of your data when it is no longer
                necessary for the purposes collected.
              </li>
              <li>
                Restriction: Request restricted processing of your data under
                certain conditions.
              </li>
              <li>
                Portability: Request transfer of your data to another
                organization.
              </li>
              <li>
                Objection: Object to data processing based on legitimate
                interests or direct marketing.
              </li>
            </ul>
            To exercise any of these rights, please contact us at{" "}
            <a href="mailto:hello@myenough.com">hello@myenough.com</a>
          </p>
          <p>
            <b>10. Cookies and Website Analytics</b>
          </p>
          <p>
            We use cookies and similar technologies to improve your experience
            on our website. Cookies help us to understand how visitors interact
            with our site, allowing us to make improvements. You can control
            cookies through your browser settings.
          </p>
          <p>For more information, please see our Cookie Policy</p>
          <p>
            <b>11. Data Security</b>
          </p>
          <p>
            We take the security of your data seriously. We have implemented
            appropriate physical, technical, and organizational measures to
            safeguard your personal data from unauthorised access, alteration,
            disclosure, or destruction.
          </p>
          <p>
            <b>12. Third-Party Links</b>
          </p>
          <p>
            Our website or communications may contain links to third-party
            websites. Please note that these websites are not governed by this
            Privacy Policy, and we are not responsible for their content or
            privacy practices.
          </p>
          <p>
            <b>13. Changes to This Privacy Policy</b>
          </p>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our operations or legal requirements. The latest version
            will always be available on our website, and where appropriate, we
            will notify you of significant changes.
          </p>
          <p>
            <b>14. Contact Us</b>
          </p>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your data, please contact us at:{" "}
            <a href="mailto:hello@myenough.com">hello@myenough.com</a>
          </p>
          <p>
            If you are not satisfied with our response, you may contact the UK
            Information Commissioner’s Office (ICO) for further assistance.
          </p>
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
