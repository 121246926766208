import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";
import TestimonyCarousel from "./TestimonyCarousel";
import ButtonLink from "./ButtonLink";

export default function ReportNowSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ textAlign: "center" }}>
      <Grid2 container alignItems={large ? "flex-start" : "center"}>
        <Grid2 xs={large ? 6 : 12}>
          <TestimonyCarousel
            interval={6000}
            indicators={false}
            stopAutoPlayOnHover
            sx={{ px: large ? 3 : 6 }}
          />
        </Grid2>
        <Grid2
          xs={large ? 6 : 12}
          display="flex"
          alignItems={large ? "flex-start" : "center"}
          justifyContent="center"
          sx={{ pt: 3 }}
        >
          <ButtonLink href={Links.Report} theme="color-outlined">
            Report Now
          </ButtonLink>
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
