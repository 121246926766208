import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function FundUsNonProfitSection() {
  return (
    <PaddedBox>
      <Typography variant="h6">
        We are a not for profit. When we start to generate revenue, we will
        reinvest everything back into enough, so that we can increase access and
        impact.
      </Typography>
      <br />
      <Typography variant="h6">
        We are setting up the Enough Foundation, all our resources will be
        re–invested in ending sexual violence worldwide.
      </Typography>
      <br />
      <Typography variant="h6">
        We’ve raised enough for our first University, and are now raising for
        more. <b>More kits = more deterrence.</b>
      </Typography>
    </PaddedBox>
  );
}
