export enum Links {
  About = "/about",
  Account = "/account",
  Allies = "/allies",
  Approach = "/approach",
  Bristol = "/bristol",
  Buy = "https://myenough.myshopify.com",
  Contact = "/contact",
  Crisis = "/crisis",
  Donate = "https://www.gofundme.com/f/6dt7e?qid=a3e6ca0f84812c24a1bbe923661cbbfa",
  Facebook = "https://www.facebook.com/enoughtoendrape",
  FAQ = "/faqs",
  Fund = "/fund",
  Guides = "/guides",
  Home = "/",
  Instagram = "https://www.instagram.com/enoughtoendrape",
  Instructions = "/howto",
  Kit = "/kit",
  Live = "/live",
  Next = "/nextsteps",
  Privacy = "/privacy",
  Recovery = "/recovery",
  Report = "/report",
  ResearchPDF = "/enough research summary.pdf",
  Social = "https://enough.honeycommb.com/login",
  Therapy = "/therapy",
  Twitter = "https://x.com/enoughtoendrape",
  Terms = "/tandcs",
  Youtube = "https://www.youtube.com/@enoughtoendrape",
}

export interface User {
  id: string;
  reports: [];
  createdDate: Date;
}

export interface Report {
  id: string;
  location: string;
  date: string;
  user: User;
  publicStories: PublicStory[];
  privateNotes: PrivateNote[];
  kits: Kit[];
  createdDate: string;
}

export interface PrivateNote {
  id: string;
  text: string;
  report: Report;
  createdDate: string;
}

export interface PublicStory {
  id: string;
  text: string;
  show: boolean;
  report: Report;
  createdDate: string;
}

export interface Kit {
  id: string;
  barcode: string;
  location: string;
  gender?: string;
  matches?: number;
  collectionDate: string;
  createdDate: string;
}

export interface ReportDTO {
  name?: string;
  email?: string;
  location: string;
  date?: string;
  publicStory?: string;
  privateNote?: string;
  kit?: KitDTO;
}

export interface KitDTO {
  barcode: string;
  location?: string;
  collectionDate?: Date;
}

export interface NoteDTO {
  text: string;
}

export interface LoginDTO {
  idToken: string;
  idp: "google" | "cognito";
}

export interface ResetDTO {
  id: string;
}
