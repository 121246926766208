import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";

import { Box, BoxProps } from "@mui/material";

interface PaddedBoxProps extends BoxProps {
  orange?: boolean;
}
export default function PaddedBox(props: PaddedBoxProps) {
  const size = useContext(SizeContext);
  const large = size === "large";
  const { sx, children, orange } = props;

  return (
    <Box
      sx={{
        backgroundColor: orange ? "secondary.main" : "common.white",
        color: orange ? "common.white" : "common.black",
        pl: large ? 18 : 4,
        pt: 8,
        pb: 12,
        pr: large ? 20 : 4,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
