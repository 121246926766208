import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

export default function InstructionsWhatsNextSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h6" color="common.white">
        What’s next?
      </Typography>
      <br />
      <Typography variant="body2" color="common.white">
        You’ll be informed of the number of DNA profiles (people) present on
        your sample via your online account.
      </Typography>
      <br />
      <Typography variant="body2" color="common.white">
        If you have anything else to keep (e.g. clothes), put them in a clean
        bag and write the date on.
      </Typography>
      <br />
      <Typography variant="body2" color="common.white">
        Try to put what happened into words. You only need to do it once, and
        the best time is now.
      </Typography>
      <br />
      <ButtonLink theme="filled" href={Links.Report}>
        Report
      </ButtonLink>
    </PaddedBox>
  );
}
