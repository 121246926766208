import React from "react";
import { Link } from "react-router-dom";
import { Links } from "../types";
import ChecklistItem from "./ChecklistItem";
import PaddedBox from "./PaddedBox";
import YouTubeThumbnailList from "./YouTubeThumbnailList";
import { Box, Stack } from "@mui/material";
import ButtonLink from "./ButtonLink";

export default function WithEnoughCheckListSection() {
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Stack gap={6}>
        <Box>
          <Link to={Links.Youtube} className="block-link" target="_blank">
            <ChecklistItem
              title="Watch recovery videos"
              subtitle="Compassionate and empowering psychoeducation in ~10 minute episodes"
            />
          </Link>
          <YouTubeThumbnailList />
        </Box>
        <Box>
          <ChecklistItem
            title="Help telling your allies"
            subtitle="Guides to help you have a productive conversation"
          />
          <ButtonLink theme="filled" href={Links.Allies}>
            See guides
          </ButtonLink>
          <br />
        </Box>
        <Box>
          <ChecklistItem
            title="Meet people who can relate"
            subtitle="A safe and empowering social network"
          />
          <ButtonLink theme="filled" href={Links.Social} target="_blank">
            Join our community
          </ButtonLink>

          <br />
        </Box>
      </Stack>
    </PaddedBox>
  );
}
