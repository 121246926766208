import React from "react";
import PageContainer from "./PageContainer";
import AboutTherapySection from "./AboutTheraphySection";

export default function TherapyPage() {
  return (
    <PageContainer>
      <AboutTherapySection />
    </PageContainer>
  );
}
