import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import PaddedBox from "./PaddedBox";
import { Typography } from "@mui/material";

export default function ReportingToPoliceSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pr: large ? "30%" : 0, pb: 3 }}>
      <Typography variant="h3">
        If you want to report to the police now, you should go there first.
      </Typography>
      <br />
      <Typography variant="body1">
        You can also report with enough and access our recovery tools if you’d
        like.
      </Typography>
      <br />
      <Typography variant="body1">
        If you decide to report to the police at a later date, you can download
        your information (including DNA results) from enough. The police will
        consider this as potentially relevant information for your case.
      </Typography>
    </PaddedBox>
  );
}
