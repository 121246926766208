import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function ForPartnersSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h6">For partners...</Typography>
      <ul>
        <li>
          <Typography variant="h6" sx={{ mb: 2 }}>
            You may feel angry that this happened, or guilt that it did. They
            weren’t responsible for what happened, and neither were you.
            Managing your anger or guilt is another emotional burden that the
            survivor does not need on their plate.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            The nature of your relationship may well change, but it can get back
            to where it was. You can play a really important role in rebuilding
            the trust and intimacy that has been shattered.
          </Typography>
        </li>
      </ul>
    </PaddedBox>
  );
}
