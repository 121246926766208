import React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import { Link } from "react-router-dom";

export default function UnderSixteenSection() {
  const childlineUrl = "https://www.childline.org.uk";
  const childline121Url =
    "https://www.childline.org.uk/get-support/1-2-1-counsellor-chat";
  const koothUrl = "https://www.kooth.com";
  return (
    <PaddedBox orange>
      <Typography variant="h1">If you‘re under 16 </Typography>
      <br />
      <Typography variant="body1">
        In addition to the listed resources, this additional info might be
        helpful:
      </Typography>
      <br />
      <Typography variant="body1">
        <Link color="white" target="_blank" to={childlineUrl}>
          Childline
        </Link>{" "}
        offers free, confidential advice and support for children and young
        people. You can have a{" "}
        <Link target="_blank" color="white" to={childline121Url}>
          1-2-1 counsellor chat online
        </Link>
        , or call them free on 0800 1111.
      </Typography>
      <br />
      <Typography variant="body1">
        Kooth is a safe online place with support for young people.
      </Typography>
      <br />
      <Typography variant="body1">
        You can create an account{" "}
        <Link color="white" to={koothUrl}>
          here
        </Link>
      </Typography>
      <br />
    </PaddedBox>
  );
}
