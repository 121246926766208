import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

export default function WhatNextSection() {
  return (
    <PaddedBox orange>
      <Stack gap={3}>
        <Typography variant="body1">
          The priority right now is your safety. Always call 999 if you are in
          danger.
        </Typography>
        <Typography variant="body1">
          Enough is not a replacement for the police, or the services offered by
          a SARC (sexual assault and rape crisis centre.)
        </Typography>
        <Typography variant="body1">
          If you are experiencing thoughts of suicide or self-harm, please call
          999 and read our Crisis Information page
        </Typography>
        <ButtonLink
          href={Links.Crisis}
          theme="color-outlined"
          sx={{
            mt: 3,
          }}
        >
          Crisis Information
        </ButtonLink>
      </Stack>
    </PaddedBox>
  );
}
