import React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";

export default function TermsPage() {
  return (
    <PageContainer>
      <PaddedBox sx={{ pt: 1 }}>
        <Typography variant="body1" sx={{ fontSize: "0.75em !important" }}>
          <p>
            <center>
              <b>Enough Terms and Conditions</b>
            </center>
          </p>

          <p>
            <b>Effective Date: 16 Sept 2024</b>
          </p>
          <hr />
          <p>
            Welcome to enough®, a platform to report, self collect DNA, and get
            support for your recovery. Please read the following Terms
            carefully, as they govern your use of Enough and our affiliates’
            products and services.
          </p>
          <hr />
          <p>
            <b>1 Introduction and Services:</b>
          </p>
          <p>
            By using any of the websites, services, and mobile apps that link to
            these Terms and Conditions (the “Terms”)—Enough®, you agree to
            these Terms. Any new features included in the Services in the future
            will also be subject to these Terms.
          </p>
          <p>
            You are entering into these Terms with This is Enough Ltd. All
            references to “Enough”, “us”, “we”, or “our” in these terms refer to
            This is Enough Ltd.
          </p>
          <p>
            If you have any questions about these Terms or our Services, please
            contact us
          </p>
          <p>
            <b>1.1 Results</b>
          </p>
          <p>
            When using our Services you may make discoveries and/or come across
            content you find offensive, inaccurate, or otherwise objectionable.
            While you may have a strong emotional reaction you expressly agree
            to assume all risks associated with your use of the Services and not
            to hold Enough liable for any social, emotional, or legal
            consequences of such discoveries or encounters. For more information
            about Enough’s Limited Liability, please review section 7 of these
            Terms.
          </p>
          <p>
            <b>1.2 Eligibility to Use the Services</b>
          </p>
          <p>
            Everyone who uses the Services, whether unregistered, free
            registered, or a purchaser or registrant of an Enough DNA kit, is a
            “User.” Users may need to register an account to use certain
            Services, and you agree to provide accurate, complete, and current
            information when registering.
          </p>

          <p>
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.
          </p>
          <p>
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>

          <p>
            <b>1.3 Use of the Services</b>
          </p>
          <p>
            In exchange for access to the Services, you agree:
            <ul>
              <li> To comply with all applicable laws;</li>
              <li>
                To be responsible for all use and activity of the Services
                associated with your account or login information;
              </li>
              <li>
                To contact us if you suspect your account has been used without
                your authorization or you believe your login information has
                been stolen;
              </li>
              <li>
                Not to resell the Services or resell, reproduce, or publish any
                content or information found on the Services, except as
                explicitly described in these Terms;
              </li>
              <li>
                Not to circumvent, disable, or otherwise interfere with
                security-related features of the Services, including by allowing
                unauthorized third parties to access Services using your
                credentials;
              </li>
              <li>
                Not to share, access, or collect data from any Services in bulk
                or attempt to access data without permission—whether manually or
                by automated means. This includes, but is not limited to, use of
                any artificial intelligence, bots, crawlers, spiders,
                data-miners, or scrapers; and
              </li>
            </ul>
          </p>
          <p>
            <b>1.4 Additional Terms Applicable to Use of DNA Services</b>
          </p>
          <p>
            The purpose of the DNA Services is to provide genetic results and
            related reports for your informational use. As used in these Terms,
            “DNA Services” refers to the use and registration of an Enough DNA
            test kit, processing and genetic testing of your sample, storing
            your sample and any extracted DNA (as applicable)
          </p>
          <p>1.4.1 Your Use of DNA Services</p>
          <p>
            In addition to the requirements in Section 1.3 above, you also
            agree:
            <ul>
              <li> Not to resell Enough DNA test kits; </li>
              <li>
                Not to send us a sample in violation of any export ban or other
                law;
              </li>
              <li>
                By providing a sample to us, you acquire no rights in any
                research or commercial products developed by us or our
                collaborators/partners and will receive no compensation related
                to any such research or product development
              </li>
            </ul>
          </p>
          <p>
            <b>1.4.2 Enough’s Provision of DNA Services</b>
          </p>
          <p>
            You agree that in order to facilitate your use of DNA Services, we
            may directly or through other companies who help us provide DNA
            Services:
            <ul>
              <li>
                Provide your sample to other companies that help us provide the
                DNA Services, such as to our laboratory partners;
              </li>
              <li>Extract DNA from your sample;</li>
              <li>
                Perform genetic tests in the United KIngdom on the extracted DNA
                using test methods available now or later-developed;
              </li>
              <li> Disclose to you, the results of the tests performed; </li>
              <li> Store your DNA Data; </li>
              <li>
                Store your sample and any extracted DNA in a biobank located in
                the United Kingdom. You may request that we destroy your sample
                and any extracted DNA.
              </li>
            </ul>
          </p>
          <p>
            <b>1.4.3 Obtaining your DNA Data</b>{" "}
          </p>
          <p>
            We protect your information as described in our privacy statement.
            If you choose to obtain a copy of your DNA Data, that copy is not
            protected by our security measures and you are solely responsible
            for storing, securing, and protecting that downloaded data. Enough
            has no responsibility if you elect to share or transfer your
            downloaded DNA Data with others, either intentionally or
            inadvertently. Your DNA Data is intended only for your personal use.
          </p>
          <p>
            <b>2. Enough Content</b>
          </p>
          <p>
            The Services contain photos, videos, documents, records, indexes of
            content, and other content provided to you by Enough (“Enough
            Content”). All Enough Content may be used only in accordance with
            these Terms, including Enough Content that may also be in the public
            domain (“Public Domain Content”).
          </p>
          <p>
            <b>2.1 Intellectual Property Rights to Enough Content</b>
          </p>
          <p>
            All elements of the Services that are created by us or under our
            direction, or where the copyright has been assigned or licensed to
            us, are subject to country-specific copyright protections around the
            world and international conventions. The Services also include
            trademarks that are owned by us and protected under country- and
            region-specific trademark laws and treaties. All uses of our
            trademarks and any goodwill resulting therefrom will inure to our
            benefit.
          </p>
          <p>
            <b>2.2 Your Use of Enough Content</b>
          </p>
          <p>
            When accessing Enough Content you agree:
            <ul>
              <li>
                To use Enough Content only in connection with your personal use
                of the Services
              </li>
              <li>
                To download Enough Content only in connection with your personal
                use or where expressly permitted by Enough;
              </li>
              <li>
                Not to remove any copyright or other proprietary notices on any
                Enough Content;
              </li>
              <li>
                Not to use significant portions of Enough Content outside the
                Services, or in a manner inconsistent with your subscription;
                and
              </li>
              <li>
                To contact us to obtain written permission to use more than a
                small number of photos and documents that are Public Domain
                Content.
              </li>
            </ul>
          </p>
          <p>
            <b>3. Your Content</b>
          </p>
          <p>
            Certain Services may allow you to contribute content, (“Your
            Content”). Your Content that contains Personal Information will be
            treated in accordance with our Privacy policy
          </p>
          <p>
            <b>3.1 You Control Your Content</b>
          </p>
          <p>
            Enough does not claim any ownership rights to Your Content, control
            how you choose to share Your Content within the Services, or limit
            how you share Your Content outside of Enough’s Services. You can
            delete Your Content either by following instructions provided within
            the Services or by logging into your Account Settings and deleting
            your Account. However, if you submit feedback, record annotations,
            or suggestions about Enough or our Services, you acknowledge that it
            is deemed to be non-confidential and non-proprietary and we may use
            your feedback, record annotations, or suggestions for any purpose
            without any obligation or compensation to you.
          </p>
          <p>
            <b>3.2 Use of Your Content</b>
          </p>
          <p>
            By submitting Your Content, you grant Enough a non-exclusive,
            sub-licensable, worldwide, royalty-free license to host, store,
            index, copy, publish, distribute, provide access to, create
            derivative works of, and otherwise use Your Content to provide,
            promote, or improve the Services, consistent with your privacy and
            sharing settings. You can terminate Enough’s license by deleting
            Your Content, except to the extent you shared Your Content with
            others and they have used Your Content. You also agree that Enough
            owns any indexes and compilations that include Your Content and may
            use them after Your Content is deleted.
          </p>
          <p>
            <b>3.3 Your Responsibilities for Your Content</b>
          </p>
          <p>
            You are responsible for the decision to create, upload, post, or
            share Your Content. By contributing or accessing Your Content you
            agree:
            <ul>
              <li>
                You have all the necessary legal rights to upload, post, or
                share Your Content;
              </li>
              <li> Your Content does not violate any applicable laws. </li>
              <li>
                Your Content that you share publicly will not include Personal
                Information as defined in our Privacy policy about a living
                person without their consent. We will not be liable if you
                publicly share information about other living people. In the
                case of living minors, you will get consent from their parent or
                guardian;
              </li>
              <li>
                If you share Your Content publicly, other users may access and
                use Your Content as part of, or in conjunction with, the
                Services. We are not required to remove any of Your Content once
                it has been publicly shared.
              </li>
              <li>
                You will use other Users’ content only within Enough Services
                and in compliance with these Terms and the other policies
                incorporated by reference
              </li>
              <li>
                Enough reserves the right to review Your Content and to screen
                for illegal content or other violations of these Terms,
                including our community rules; and to remove or disable access
                to illegal content or Your Content that we believe violates
                these Terms. We will also remove Your Content in response to a
                valid court order or as required by applicable law; and
              </li>
              <li>
                Serious or repeat violations or offenses will subject you to
                account suspension or termination in accordance any Enough
                content moderation policies
              </li>
            </ul>
          </p>
          <p>
            <b>4. Termination or Suspension of Your Account</b>
          </p>
          <p>
            We reserve the right to limit, suspend, or terminate your access to
            the Services if you breach these Terms. If we exercise those rights,
            we will provide you with the reason for our decision. If a decision
            is based on the illegality of Your Content, we will also explain why
            we believe it is illegal. If applicable laws where you live require
            additional notice or process, we will provide such notice and
            process to you. Unless otherwise required by applicable law we will
            not refund subscription fees or the purchase price of a DNA test kit
            where you lose access to the Services because of your breach of the
            Terms
          </p>
          <p>
            <b>5. No Guarantees or Warranties</b>
          </p>
          <p>
            Subject to Section 10.2.1, we provide the Services and the Enough
            Content to you on an “AS-IS” basis, meaning without any guarantee or
            warranty. To the maximum extent permitted by law, we disclaim all
            warranties express or implied, including the implied warranties of
            non-infringement, merchantability, and fitness for a particular
            purpose. We do not make any promises about (a) the Enough Content,
            (b) Your Content, (c) the specific functionality of the Services,
            (d) the quality, accuracy, reliability, or availability of the
            Enough Content or Services, or (e) that the Services will be free
            from viruses or other harmful components.
          </p>
          <p>
            Enough is not a medical service and the information we provide is
            not a substitute for professional medical advice, diagnosis,
            treatment, or counseling. If you are injured or require immediate
            medical attention, please call 999 or visit a hospital.
          </p>
          <p>
            Enough is not endorsed by, directly affiliated with, authorized, or
            sponsored by any university, college, or third-party institution
            that may be referenced on this website or listed as a location where
            our Services are available.
          </p>
          <p>
            We are constantly working to improve our Services. Enough may add or
            remove functionality or features from the Services, or adjust what
            services are available
          </p>
          <p>
            <b>6. Enough’s Limited Liability</b>
          </p>
          <p>
            By using the Services, you agree that Enough’s liability is limited
            to the fullest extent allowed by law. We will not be liable for any
            unintentional damage, any actual, incidental, or consequential
            damage, or for any loss or claim of any kind. If you live in a
            jurisdiction that does not allow us to broadly limit our liability,
            some of these limitations may not apply to you.
          </p>
          <p>
            If you are dissatisfied with any portion of the Services or with any
            statement in these Terms, your sole remedy is to stop using the
            Services. Our total liability in any matter related to the Services
            or these Terms is limited to the aggregate amount you paid to us
            during the 12-month period preceding the event giving rise to the
            liability.
          </p>
          <p>
            <b>7. Your Indemnity</b>
          </p>
          <p>
            You agree that you will indemnify and hold Enough, and its
            affiliates and subsidiaries their respective officers, directors,
            employees, agents, successors, and assigns (the “Enough Parties”)
            harmless from any claims, damages, or other expenses (including
            legal fees) that result from your use of the Services and (a) your
            violation of these Terms or other documents or policies incorporated
            herein by reference; (b) your violation of another person’s rights;
            or (c) any claim related to Your Content, including a claim that
            Your Content caused damage to another person. This indemnification
            obligation will continue after you stop using the Services. In
            addition, you release the Enough Parties from all claims, demands,
            actions, or suits in connection with Your Content, including any
            liability related to our use or non-use of Your Content, claims for
            defamation, invasion of privacy, right of publicity, emotional
            distress, or economic loss.
          </p>
          <p>
            <b>8. Services Offered by Other Companies</b>
          </p>
          <p>
            Our Services may contain links to websites operated by third-parties
            but that does not constitute sponsorship, endorsement, approval, or
            responsibility for any content or its accuracy. Enough does not
            warrant any offers or make any guarantees about third-party sites,
            their content, products, or services, and will not be liable for any
            harm or damages caused by them. Please read the terms and conditions
            and privacy documentation for all third-party sites carefully, as
            they may differ substantially from Enough.
          </p>
          <p>
            <b>9. Dispute Resolution, Arbitration and Class Action Waiver</b>
          </p>
          <p>
            PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
            INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
          </p>
          <p>
            We work hard to keep our customers satisfied. If a dispute arises
            between you and Enough, our goal is to provide a cost-effective
            means of quickly resolving the dispute. If you have any concern or
            dispute about the Services, you agree to first try to resolve the
            dispute informally by contacting us
          </p>
          <p>
            You will benefit from any mandatory provisions of UK law and nothing
            in this Agreement affects your rights as a consumer to rely on such
            mandatory provisions. We provide you with a legal guarantee that the
            Service will be in legal conformity at the time of supply and during
            the life of our contract with you. Under this legal guarantee, we
            will be liable for any lack of conformity of the Service and you may
            have a right of remedy. We will give you as much notice as
            reasonably feasible of any removal or suspension or stopping of a
            Service or of any functionality or features.
          </p>
          <p>
            You are entitled to bring any judicial proceedings relating to this
            Agreement before the competent court of England and Wales, you agree
            that England and Wales will have exclusive jurisdiction over all
            disputes (contractual or non contractual) related to this Agreement.
            If Enough seeks to enforce any of its rights against you as a
            consumer, we may do so only in the courts of the jurisdiction in
            which you are a resident.
          </p>
          <p>
            <b>10. Miscellaneous</b>
          </p>
          <p>
            <b>10.1 Modifications</b>
          </p>
          <p>
            We may modify these Terms at any time and agree to notify you of any
            material changes by posting information through the Services or
            sending you an email. Material changes become effective thirty days
            after they are posted, except for changes addressing new Services or
            legal requirements, which will be effective immediately. Your
            continued use of the Services means you accept the modified Terms.
            If you do not agree to the changes, you should stop using the
            Services
          </p>
          <p>
            <b>10.2 Entire Agreement</b>
          </p>
          <p>
            These Terms and other documents incorporated by reference are the
            entire agreement between you and Enough regarding your use of the
            Services and supersede any prior agreements.
          </p>
          <p>
            <b>10.3 Transfer of Rights and Obligations</b>
          </p>
          <p>
            We reserve the right to assign or transfer our rights and
            obligations under this Agreement. If Enough or any Service provider
            is acquired or transferred to another entity (in whole or part, and
            including in connection with bankruptcy or similar proceedings), you
            agree that Enough may share your Personal Information and Your
            Content with that entity. You may not assign or transfer any of your
            rights and obligations under these Terms without Enough’s written
            consent. There are no third-party beneficiaries to these Terms.
          </p>
          <p>
            <b>10.4 Severability</b>
          </p>
          <p>
            Except as explicitly provided herein, the unenforceability of any
            section or clause in these Terms will not affect the enforceability
            of the remaining Terms.
          </p>
          <p>
            <b>10.5 No Waiver</b>
          </p>
          <p>
            Our failure to enforce any provision of these Terms is not a waiver
            of our rights under that provision, and we reserve all rights not
            expressly provided to you herein.
          </p>
          <p>
            <b>10.6 Minors Thirteen and Under.</b>
          </p>
          <p>
            Enough does not knowingly seek or collect any personal information
            directly from anyone under the age of 13 and will take commercially
            reasonable efforts to delete such data from our systems.
          </p>

          <p>
            <b>10.7 Unsolicited Idea Submission Policy</b>
          </p>
          <p>
            Enough does not accept, review, or consider unsolicited ideas or
            materials (including, without limitation, new advertising campaigns,
            new promotions, new products or technologies, processes, materials,
            marketing plans, or new product names) other than those Enough has
            specifically requested. Please do not send your unsolicited ideas or
            materials to Enough or anyone at Enough. If you do send them, you
            understand and agree that Enough will consider the unsolicited ideas
            and materials you submit non-confidential and non-proprietary, and
            Enough will be entitled to unrestricted use of these ideas and
            materials, without obligations, contractual or otherwise, and
            without any compensation to you.
          </p>
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
