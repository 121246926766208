import React from "react";
import StatsSection from "./StatsSection";
import PageContainer from "./PageContainer";
import AboutInfoSection from "./AboutInfoSection";

export default function AboutPage() {
  return (
    <PageContainer>
      <StatsSection />
      <AboutInfoSection />
    </PageContainer>
  );
}
