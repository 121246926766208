import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import HeroImage from "./HeroImage";
import kit from "../img/kit2.png";
import KitCheckListSection from "./KitChecklistSection";
import GetKitsSection from "./GetKitsSection";
import BristolStudentSection from "./BristolStudenSection";
import PageContainer from "./PageContainer";
import PaddedBox from "./PaddedBox";
import FAQKitSection from "./FAQKitSection";

export default function KitPage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PageContainer>
      <HeroImage
        src={kit}
        width={large ? "40%" : "70%"}
        sx={{
          pt: large ? 14 : 7,
          pb: large ? 14 : 7,
          background: "linear-gradient(black, grey)",
        }}
      />
      <BristolStudentSection />
      <KitCheckListSection />
      <GetKitsSection />
      <PaddedBox>
        <FAQKitSection title="FAQs" />
      </PaddedBox>
    </PageContainer>
  );
}
