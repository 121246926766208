import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchReports } from "../api";
import { Report } from "../types";
import { authContext } from "./AuthProvider";

interface ReportsContext {
  reports: Report[];
  refreshReports: () => Promise<void>;
}

export const reportsContext = createContext<ReportsContext>({
  reports: [],
  refreshReports: async () => {},
});

export const ReportsProvider = (props: React.PropsWithChildren) => {
  const [reports, setReports] = useState<Report[]>([]);
  const { accessToken } = useContext(authContext);

  const refreshReports = async () => {
    try {
      const result = await fetchReports(accessToken);
      setReports(result);
    } catch (e) {
      console.error("Could not fetch reports");
    }
  };

  useEffect(() => {
    if (accessToken) {
      refreshReports();
    } else {
      setReports([]);
    }
  }, [accessToken]);

  return (
    <reportsContext.Provider value={{ reports, refreshReports }}>
      {props.children}
    </reportsContext.Provider>
  );
};
