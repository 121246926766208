import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";

export default function SupportNumbersSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox>
      <Stack>
        <Typography variant="h3">
          If you’d like to speak to someone right now
        </Typography>
        <br />
        <Typography variant="body1">
          You can speak to a trained professional at any time through any one of
          these services:
        </Typography>
        <br />
        <Grid2 container gap={large ? 0 : 6}>
          <Grid2 xs={large ? 4 : 12}>
            <Stack alignItems="center" gap={large ? 2 : 0} sx={{ px: 2 }}>
              <Typography fontWeight="bold" variant="body2">
                Rape Crisis
              </Typography>
              <Typography variant="caption" fontFamily="Recoleta Light">
                0808 500 2222
              </Typography>
              <Link
                style={{ textDecoration: "none" }}
                to="https://rapecrisis.org.uk"
                target="_blank"
              >
                <Typography variant="caption" fontFamily="Recoleta Light">
                  rapecrisis.org.uk
                </Typography>
              </Link>
              <Typography
                variant="caption"
                textAlign="center"
                fontFamily="Recoleta Light"
              >
                24/7 support (16+) via phone or online chat
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 4 : 12}>
            <Stack alignItems="center" gap={large ? 2 : 0} sx={{ px: 2 }}>
              <Typography fontWeight="bold" variant="body2">
                Samaritans
              </Typography>
              <Typography variant="caption" fontFamily="Recoleta Light">
                116 123
              </Typography>
              <Link
                to="https://www.samaritans.org"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Typography variant="caption" fontFamily="Recoleta Light">
                  www.samaritans.org
                </Typography>
              </Link>
              <Typography
                variant="caption"
                textAlign="center"
                fontFamily="Recoleta Light"
              >
                24/7 support via phone
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 4 : 12}>
            <Stack alignItems="center" gap={large ? 2 : 0} sx={{ px: 2 }}>
              <Typography fontWeight="bold" variant="body2">
                Shout
              </Typography>
              <Typography variant="caption" fontFamily="Recoleta Light">
                85258
              </Typography>
              <Link
                to="https://giveusashout.org/get-help/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Typography variant="caption" fontFamily="Recoleta Light">
                  giveusashout.org/get-help/
                </Typography>
              </Link>

              <Typography
                variant="caption"
                fontFamily="Recoleta Light"
                textAlign="center"
              >
                24/7 support via text. Text ‘shout’ to the number above
              </Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Stack>
    </PaddedBox>
  );
}
