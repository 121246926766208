import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function ForParentsSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h6">For parents...</Typography>
      <ul>
        <li>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Don’t assume the role of rescuer. It’s all you might want to do, but
            it takes away their agency
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            Try to listen from outside the structure of the relationship you
            have with them. There are implicit hierarchies and power dynamics at
            play which aren’t necessarily productive in this situation.{" "}
          </Typography>
        </li>
      </ul>
    </PaddedBox>
  );
}
