import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { SizeContext } from "./App";
import { reportStatsContext } from "./ReportStatsProvider";
import PaddedBox from "./PaddedBox";

const titleStyle = { mb: "0.5rem" };
const subtitleStyle = { fontSize: "2rem" };

export default function LiveWidgetSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  const reportCount = useContext(reportStatsContext);
  const { count, latest } = reportCount;

  return (
    <PaddedBox orange sx={{ py: 8 }}>
      <Grid2 container>
        <Grid2 xs={6}>
          <Stack sx={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              sx={{ fontSize: large ? "9rem !important" : "4rem !important" }}
              color="common.white"
            >
              {count}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={6} sx={{ textAlign: "left" }}>
          <Stack>
            <Typography variant="h2" color="common.white" sx={titleStyle}>
              {count === 1 ? "Report" : "Reports"}
            </Typography>
            <Typography variant="body1" color="common.white" sx={subtitleStyle}>
              last report {latest}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
