import React from "react";
import { Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import { Link } from "react-router-dom";
import { Links } from "../types";

export default function Call999() {
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Stack>
        <Typography variant="h3">If you don’t feel safe</Typography>
        <br />
        <Typography variant="body1">
          If you feel yourself or someone else is in immediate danger, always
          call 999. Whether that be due to needing urgent medical attention, or
          because someone is trying to harm you or someone else around you.
        </Typography>
        <br />
        <Typography variant="body1">
          If you are physically safe but need urgent mental health support have
          a read of our <Link to={Links.Crisis}>Serious Crisis</Link> page.
        </Typography>
      </Stack>
    </PaddedBox>
  );
}
