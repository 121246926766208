import * as React from "react";
import { Box, Stack } from "@mui/material";

interface HeroImageProps {
  src: string;
  width?: string;
  sx?: any;
}
export default function HeroImage(props: HeroImageProps) {
  const sx = { maxWidth: "100%" };

  return (
    <Stack
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "secondary.main",
        pl: 4,
        pr: 4,
        pb: 6,
        pt: 14,
        ...props.sx,
      }}
    >
      <Stack sx={{ width: props.width || "50%" }}>
        <Box component="img" src={props.src} sx={sx} />
      </Stack>
    </Stack>
  );
}
