import React from "react";
import { Typography } from "@mui/material";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";
import YouTubeVideo from "./YouTubeVideo";

export default function ReadOurApproachSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h4" color="common.white">
        Our resources have been created by an outstanding professional
        clinician, informed by the highest therapeutic principles. However we
        are not a clinical service, our resources are designed to provided
        additional support depending on what you can access and what works for
        you.
      </Typography>
      <br />
      <ButtonLink href={Links.Approach} theme="filled">
        Read our approach
      </ButtonLink>
      <br />
      <br />
      <YouTubeVideo src="https://www.youtube.com/embed/pAuHqPegL6o?si=r4zOXMni-o48kNIf" />
    </PaddedBox>
  );
}
