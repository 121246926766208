import React, { useContext, useState } from "react";
import { SizeContext } from "./App";
import { Box, Button, Stack, Typography, TextField } from "@mui/material";
import { Kit, KitDTO, PrivateNote, PublicStory, Report } from "../types";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ReportStoryView from "./ReportStoryView";
import { formatFullDate, formatShortDate } from "../util";
import { createNote, createKit, deleteReport } from "../api";
import { authContext } from "./AuthProvider";
import { reportsContext } from "./ReportsProvider";
import DeleteModal from "./DeleteModal";
import moment from "moment";

interface ReportViewProps {
  report: Report;
  num?: number;
}

export default function ReportView(props: ReportViewProps) {
  const { accessToken } = useContext(authContext);
  const { refreshReports } = useContext(reportsContext);
  const size = useContext(SizeContext);
  const large = size === "large";
  const { report } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [showKitForm, setShowKitForm] = useState(false);
  const [privateNote, setPrivateNote] = useState("");
  const [barcode, setBarcode] = useState("");
  const [kitDate, setKitDate] = useState<moment.Moment | null>(moment());
  const [kitLocation, setKitLocation] = useState("");

  const handleNoteSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const payload = { text: privateNote };
    await createNote(payload, report.id, accessToken);
    setShowNoteForm(false);
    setPrivateNote("");
    await refreshReports();
  };

  const handleKitSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let payload: KitDTO = { barcode };
    if (kitDate) payload.collectionDate = kitDate.toDate();
    await createKit(payload, report.id, accessToken);
    setShowKitForm(false);
    setKitDate(null);
    setBarcode("");
    setKitLocation("");
    await refreshReports();
  };

  const handleDeleteReport = async () => {
    try {
      await deleteReport(report.id, accessToken);
      await refreshReports();
    } catch (e) {
      console.error("Problem deleting report");
    }
  };

  const inputStyle = {
    border: "2px",
    "& input::placeholder": {
      color: "common.black",
      opacity: 1,
    },
    "& textarea::placeholder": {
      color: "common.black",
      opacity: 1,
    },
    "& fieldset": {
      borderRadius: "0",
    },
  };

  return (
    <Stack gap={large ? 8 : 4} className="report-view">
      <Box
        sx={{
          mb: 4,
          p: 4,
          border: "solid 2px lightslategray",
          borderRadius: "1rem",
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {props.num && (
            <Typography variant="body1">Report {props.num}</Typography>
          )}
          <Typography variant="body1">Location: {report.location}</Typography>
          {!!report.date && (
            <Typography variant="body1">
              Date: {formatShortDate(report.date)}
            </Typography>
          )}
        </Stack>
        {!!report.publicStories.length && (
          <Box>
            <Typography variant="subtitle1" fontSize="1.25rem">
              Public story
            </Typography>
            {report.publicStories.map((story: PublicStory) => {
              return (
                <ReportStoryView
                  key={story.id}
                  createdDate={story.createdDate}
                  style="Story"
                  text={story.text}
                />
              );
            })}
          </Box>
        )}
        {!!report.privateNotes.length && (
          <Box>
            <Typography variant="subtitle1" fontSize="1.25rem">
              Private notes
            </Typography>

            {[...report.privateNotes]
              .sort(
                (a: PrivateNote, b: PrivateNote) =>
                  new Date(a.createdDate).getTime() -
                  new Date(b.createdDate).getTime()
              )
              .map((story: PrivateNote) => {
                return (
                  <ReportStoryView
                    key={story.id}
                    text={story.text}
                    createdDate={story.createdDate}
                    style="Note"
                  />
                );
              })}
          </Box>
        )}

        <Stack direction="row" justifyContent="right">
          <Button
            variant="contained"
            onClick={() => setShowNoteForm(!showNoteForm)}
          >
            Add private note
          </Button>
        </Stack>
        {!!showNoteForm && (
          <form onSubmit={handleNoteSubmit}>
            <TextField
              id="private-note"
              type="text"
              autoComplete="off"
              // See https://github.com/mui/base-ui/issues/167#issuecomment-1987025654
              InputProps={{
                rows: 5,
                multiline: true,
                inputComponent: "textarea",
              }}
              onChange={(e) => {
                setPrivateNote(e.currentTarget.value);
              }}
              value={privateNote}
              placeholder="Write as much or as little as you’d like"
              sx={{ width: "100%", my: 3 }}
            />

            <Stack direction="row" justifyContent="right">
              <Button
                variant="contained"
                disabled={!!!privateNote}
                id="notesubmit"
                type="submit"
              >
                Save note
              </Button>
            </Stack>
          </form>
        )}
        {!!report.kits.length && (
          <Box sx={{ my: 3 }}>
            <Typography variant="subtitle1" fontSize="1.25rem">
              Kits
            </Typography>

            {report.kits.map((kit: Kit) => {
              return (
                <Stack
                  sx={{ borderTop: "1px solid black", py: 3 }}
                  key={kit.barcode}
                >
                  <Typography>Barcode: {kit.barcode}</Typography>
                  {kit.gender && <Typography>Result: {kit.gender}</Typography>}
                  {!!kit.collectionDate && (
                    <Typography>
                      Collection date: {formatShortDate(kit.collectionDate)}
                    </Typography>
                  )}
                  {!!kit.location && (
                    <Typography>Location {kit.location}</Typography>
                  )}
                  <Typography variant="caption">
                    Kit record created {formatFullDate(kit.createdDate)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        )}

        <Stack direction="row" justifyContent="right">
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => setShowKitForm(!showKitForm)}
          >
            Add kit
          </Button>
        </Stack>
        {showKitForm && (
          <form onSubmit={handleKitSubmit}>
            <Stack sx={{ my: 2 }} gap={3}>
              <TextField
                id="barcode"
                onChange={(e) => {
                  setBarcode(e.currentTarget.value);
                }}
                value={barcode}
                type="text"
                autoComplete="off"
                required={showKitForm}
                placeholder="Kit barcode"
                sx={inputStyle}
              />
              <TextField
                id="location"
                onChange={(e) => {
                  setKitLocation(e.currentTarget.value);
                }}
                value={kitLocation}
                type="text"
                autoComplete="off"
                placeholder="Kit location"
                sx={inputStyle}
              />
              <DateTimePicker
                label="Date (of using the kit)"
                value={kitDate}
                sx={{
                  "& div": {
                    borderRadius: "0",
                  },
                }}
                onChange={(newDate) => setKitDate(newDate)}
              />

              <Stack direction="row" justifyContent="right">
                <Button
                  disabled={!!!barcode}
                  id="kitsubmit"
                  type="submit"
                  variant="contained"
                >
                  Save kit
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
        <Button
          onClick={() => setModalOpen(true)}
          variant="contained"
          color="error"
        >
          Delete report
        </Button>

        <Stack sx={{ mt: 2 }}>
          <Typography variant="caption">Report ID: {report.id}</Typography>
          <Typography variant="caption">
            Report created {formatFullDate(report.createdDate)}
          </Typography>
        </Stack>
        <DeleteModal
          open={modalOpen}
          title="Delete Report"
          description="Permanently delete this report and all associated data. This cannot be undone."
          dismissMessage="Keep report"
          deleteMessage="Delete report"
          handleDelete={handleDeleteReport}
          handleClose={() => setModalOpen(false)}
        />
      </Box>
    </Stack>
  );
}
