import { Box, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { SizeContext } from "./App";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import PaddedBox from "./PaddedBox";

export default function GuideIntroSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox display="flex" sx={{ pt: 0 }}>
      <Box sx={{ width: "75%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bolder" }}
          color="secondary.main"
        >
          We’ve put together this quick guide to help you take your sample and
          clarify any questions you might have.
        </Typography>
        <br />
        <Typography
          variant="h6"
          sx={{ fontWeight: "bolder" }}
          color="secondary.main"
        >
          It’s worth having a look at our website and FAQs - there’s lots more
          helpful info on there.
        </Typography>

        <Grid2 container>
          <Grid2 xs={large ? 6 : 12}>
            <Stack gap={1} sx={{ pr: large ? 6 : 0, mt: 3 }}>
              <Typography fontFamily="Avenir Extra Bold" variant="caption">
                What is this?
              </Typography>
              <Typography variant="caption">
                A DNA kit for you to use discreetly and quickly.
              </Typography>
              <Typography variant="caption">
                Results that belong to you and no one else.
              </Typography>
              <Typography variant="caption">
                The first step in your recovery.
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                This kit is for all genders and can be used after showering.
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Stack gap={1} sx={{ pr: large ? 6 : 0, mt: 3 }}>
              <Typography fontFamily="Avenir Extra Bold" variant="caption">
                What’s the water for?
              </Typography>
              <Typography variant="caption">
                It’s important for the swab to be damp (so it can pick up dried
                evidence.)
              </Typography>
              <Typography variant="caption">
                But please make sure it is damp, not dripping! The best way to
                do this is to squeeze a couple of drops on to the swab.
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Stack gap={1} sx={{ pr: large ? 6 : 0, mt: 3 }}>
              <Typography fontFamily="Avenir Extra Bold" variant="caption">
                What do I do?
              </Typography>
              <Typography variant="caption">
                1. Unseal the swab (not before)
              </Typography>
              <Typography variant="caption">
                2. Read the options for where to use the swab. If several apply,
                go for the first (they are ordered by likely quality of DNA.)
              </Typography>
              <Typography variant="caption">
                3. Put it back in the envelope and send it via the post office.
                If you can’t straightaway, put it in the fridge until you can.
                The freepost label takes it straight to the lab.
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Stack gap={1} sx={{ pr: large ? 6 : 0, mt: 3 }}>
              <Typography fontFamily="Avenir Extra Bold" variant="caption">
                Where can it be used?
              </Typography>
              <Typography variant="caption">
                You can take a sample from: Vagina, anus, vulva, mouth, skin
                (see next pages for detail.) The best results are from liquid
                e.g. sperm/saliva, even if now dry.
              </Typography>
              <Typography variant="caption">
                We’ve ordered these by strongest likely DNA presence, but you
                should test wherever is most comfortable, as long as their skin
                came into contact with yours.
              </Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </PaddedBox>
  );
}
