import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  ConfirmForgotPasswordCommand,
  AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider";

const { REACT_APP_COGNITO_CLIENT_ID } = process.env;

export const cognitoClient = new CognitoIdentityProviderClient({
  region: "eu-west-2",
});

export const signIn = async (username: string, password: string) => {
  const params = {
    AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
    ClientId: REACT_APP_COGNITO_CLIENT_ID as string,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const signUp = async (email: string, password: string) => {
  const params = {
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
    Username: email,
    Password: password,
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
    ],
  };
  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  const params = {
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
    ConfirmationCode: code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};

export const confirmPasswordReset = async (
  username: string,
  password: string,
  code: string
) => {
  const params = {
    ClientId: REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
    ConfirmationCode: code,
    Password: password,
  };
  try {
    const command = new ConfirmForgotPasswordCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error confirming password: ", error);
    throw error;
  }
};
