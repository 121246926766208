import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

export default function GetKitsSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox orange>
      <Grid2 container>
        <Grid2
          xs={large ? 6 : 12}
          display="flex"
          sx={{ mb: large ? 0 : 8 }}
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap={2} alignItems="center" textAlign="center">
            <Typography color="common.white" variant="h3">
              Get kits
            </Typography>
            <Typography color="common.white" variant="subtitle1">
              Sent to you or a loved one.
            </Typography>
            <ButtonLink theme="filled" href={Links.Buy}>
              Buy now
            </ButtonLink>
          </Stack>
        </Grid2>
        <Grid2
          xs={large ? 6 : 12}
          display="flex"
          sx={{ mb: large ? 0 : 4 }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack gap={2} alignItems="center" textAlign="center">
            <Typography color="common.white" variant="h3">
              Donate
            </Typography>
            <Typography color="common.white" variant="subtitle1">
              And give kits.
            </Typography>
            <ButtonLink theme="filled" href={Links.Donate}>
              Donate now
            </ButtonLink>
          </Stack>
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
