import React, { useContext, useEffect, useState } from "react";
import { SizeContext } from "./App";
import { LoginDTO } from "../types";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LoginModal, { AuthFlow } from "./LoginModal";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography,
  Alert,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";
import moment from "moment";

import { TextField } from "@mui/material";
import { createReport } from "../api";
import { reportsContext } from "./ReportsProvider";
import { authContext } from "./AuthProvider";

import ReportExamplesSection from "./ReportExamplesSection";
import { KitDTO } from "../types";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ReportSavedModal from "./ReportSavedModal";

enum SubmissionState {
  IDLE,
  SUBMITTING,
  SUCCESS,
  FAIL,
}

interface ReportFormSectionProps {
  onSuccess: () => void;
}

export default function ReportFormSection(props: ReportFormSectionProps) {
  const size = useContext(SizeContext);
  const large = size === "large";
  const { accessToken, setIdToken } = useContext(authContext);
  const { refreshReports } = useContext(reportsContext);

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [savedModalOpen, setSavedModalOpen] = useState(false);
  const [submitOnToken, setSubmitOnToken] = useState(false);

  const [hasKit, setHasKit] = useState<boolean>(false);
  const [publicStory, setPublicStory] = useState<string>("");
  const [privateNote, setPrivateNote] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [kitDate, setKitDate] = useState<moment.Moment | null>(moment());
  const [kitTime, setKitTime] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [kitLocation, setKitLocation] = useState<string>("");
  const [collectionConsent, setCollectionConsent] = useState<boolean>(false);
  const [sampleConsent, setSampleConsent] = useState<boolean>(false);
  const [shareConsent, setShareConsent] = useState<boolean>(false);
  const [researchConsent, setResearchConsent] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [submissionState, setSubmissionState] = useState<SubmissionState>(
    SubmissionState.IDLE
  );

  const clearForm = () => {
    setHasKit(false);
    setPublicStory("");
    setPrivateNote("");
    setBarcode("");
    setKitDate(null);
    setKitTime("");
    setKitLocation("");
    setLocation("");
    setCollectionConsent(false);
    setSampleConsent(false);
    setShareConsent(false);
    setResearchConsent(false);
  };

  const handleLoginResponse = async (dto: LoginDTO) => {
    if (setIdToken) {
      setIdToken(dto);
      setSubmitOnToken(true);
      setSubmissionState(SubmissionState.SUBMITTING);
    }
    setLoginModalOpen(false);
  };

  useEffect(() => {
    if (accessToken && submitOnToken) submitReport();
  }, [accessToken, submitOnToken]);

  const submitReport = async () => {
    const kitPayload: KitDTO = {
      barcode,
      location: kitLocation,
    };

    if (kitDate) kitPayload.collectionDate = kitDate.toDate();

    const payload = kitPayload.barcode
      ? {
          location,
          publicStory,
          privateNote,
          kit: kitPayload,
        }
      : {
          location,
          publicStory,
          privateNote,
        };

    try {
      const result = await createReport(payload, accessToken);
      if (result.status == 200) {
        clearForm();
        setSubmissionState(SubmissionState.SUCCESS);
        props.onSuccess();
        setSavedModalOpen(true);
        await refreshReports();
      } else {
        setSubmissionState(SubmissionState.FAIL);
      }
    } catch (e) {
      setSubmissionState(SubmissionState.FAIL);
    }
    setSubmitOnToken(false);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setSubmissionState(SubmissionState.SUBMITTING);
    e.preventDefault();

    if (!accessToken) {
      setLoginModalOpen(true);
    } else {
      await submitReport();
    }
  };

  useEffect(() => {
    let valid = false;
    if (hasKit) {
      valid =
        !!location &&
        !!barcode &&
        collectionConsent &&
        sampleConsent &&
        shareConsent &&
        researchConsent;
    } else {
      valid = !!location;
    }
    setIsFormValid(valid);
  }, [
    hasKit,
    publicStory,
    privateNote,
    barcode,
    kitDate,
    kitTime,
    location,
    kitLocation,
    collectionConsent,
    sampleConsent,
    shareConsent,
    researchConsent,
  ]);

  const inputStyle = {
    mb: 3,
    "& input::placeholder": {
      color: "common.black",
      fontFamily: "Recoleta Light",
      opacity: 0.45,
      textAlign: "center",
    },
    "& textarea::placeholder": {
      color: "common.black",
      fontFamily: "Recoleta Light",
      opacity: 0.45,
      textAlign: "center",
      lineHeight: large ? "12rem" : "3rem",
    },
    "& fieldset": {
      borderColor: "orange",
      borderWidth: "2px",
    },
  };

  return (
    <>
      <PaddedBox>
        <form onSubmit={handleSubmit}>
          <Stack gap={large ? 8 : 4}>
            <Box>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h4">What happened</Typography>
                <Typography variant="body2">
                  Think about when, where, who, how you felt, if it happened
                  multiple times.
                </Typography>
              </Box>
              <TextField
                id="private-note"
                type="text"
                autoComplete="off"
                // See https://github.com/mui/base-ui/issues/167#issuecomment-1987025654
                InputProps={{
                  rows: 5,
                  multiline: true,
                  inputComponent: "textarea",
                }}
                onChange={(e) => {
                  setPrivateNote(e.currentTarget.value);
                }}
                value={privateNote}
                placeholder="Write as much or as little as you’d like"
                sx={{ ...inputStyle, width: "100%" }}
              />
            </Box>
            <Box>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h4">
                  What you want to tell the world
                </Typography>
                <Typography variant="body2">
                  Anonymous, shared via social media
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <ReportExamplesSection />
              </Box>

              <TextField
                id="public-story"
                type="text"
                autoComplete="off"
                minRows={2}
                // See https://github.com/mui/base-ui/issues/167#issuecomment-1987025654
                InputProps={{
                  rows: 5,
                  multiline: true,
                  inputComponent: "textarea",
                }}
                onChange={(e) => {
                  setPublicStory(e.currentTarget.value);
                }}
                value={publicStory}
                placeholder="No names or swear words please"
                sx={{ ...inputStyle, width: "100%" }}
              />
            </Box>
            <Grid2 container>
              <FormControl>
                <FormLabel id="has-kit">I have a DNA sample:</FormLabel>
                <RadioGroup
                  aria-labelledby="has-kit"
                  defaultValue="No"
                  name="has-kit"
                  value={hasKit ? "Yes" : "No"}
                  onChange={() => setHasKit(!hasKit)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid2>
            <Stack gap={2}>
              {hasKit && (
                <>
                  <TextField
                    id="barcode"
                    onChange={(e) => {
                      setBarcode(e.currentTarget.value);
                    }}
                    value={barcode}
                    type="text"
                    autoComplete="off"
                    required={hasKit}
                    placeholder="Kit barcode (if you have one)"
                    sx={inputStyle}
                  />
                  <DateTimePicker
                    label="Date (of using the kit)"
                    value={kitDate}
                    onChange={(newDate) => setKitDate(newDate)}
                    sx={{ ...inputStyle }}
                  />
                </>
              )}
              <TextField
                id="location"
                onChange={(e) => {
                  setLocation(e.currentTarget.value);
                }}
                value={location}
                required={true}
                type="text"
                autoComplete="off"
                placeholder="Location (e.g. Bristol)"
                sx={inputStyle}
              />

              {hasKit && (
                <TextField
                  id="kit-location"
                  onChange={(e) => {
                    setKitLocation(e.currentTarget.value);
                  }}
                  value={kitLocation}
                  type="text"
                  autoComplete="off"
                  placeholder="Kit location (where you swabbed)"
                  sx={inputStyle}
                />
              )}
            </Stack>
            {hasKit && (
              <Stack gap={2}>
                <FormGroup>
                  <FormControlLabel
                    disableTypography
                    required={hasKit}
                    sx={{
                      fontSize: "1.25rem",
                      mb: 2,
                      alignItems: large ? "flex-end" : "flex-start",
                    }}
                    control={
                      <Checkbox
                        id="collection-consent"
                        checked={collectionConsent}
                        onChange={(e) => {
                          setCollectionConsent(e.target.checked);
                        }}
                      />
                    }
                    label="I consent to collection of a tissue sample and subsequent DNA analysis."
                  />
                  <FormControlLabel
                    disableTypography
                    required={hasKit}
                    sx={{
                      fontSize: "1.25rem",
                      mb: 2,
                      alignItems: large ? "flex-end" : "flex-start",
                    }}
                    control={
                      <Checkbox
                        id="sample-consent"
                        checked={sampleConsent}
                        onChange={(e) => {
                          setSampleConsent(e.target.checked);
                        }}
                      />
                    }
                    label="I declare that the sample, named as above is my sample and that I am over the age of 16."
                  />
                  <FormControlLabel
                    disableTypography
                    required={hasKit}
                    sx={{
                      fontSize: "1.25rem",
                      mb: 2,
                      alignItems: large ? "flex-end" : "flex-start",
                    }}
                    control={
                      <Checkbox
                        id="share-consent"
                        checked={shareConsent}
                        onChange={(e) => {
                          setShareConsent(e.target.checked);
                        }}
                      />
                    }
                    label="I would like the laboratory to share my test results with enough who will pass them on to me."
                  />
                  <FormControlLabel
                    disableTypography
                    required={hasKit}
                    sx={{
                      fontSize: "1.25rem",
                      mb: 2,
                      alignItems: large ? "flex-end" : "flex-start",
                    }}
                    control={
                      <Checkbox
                        id="research-consent"
                        checked={researchConsent}
                        onChange={(e) => {
                          setResearchConsent(e.target.checked);
                        }}
                      />
                    }
                    label="I am happy for this sample to be used after tests are complete as a normal control for scientific or medical research. No personal information will be identifiable."
                  />
                </FormGroup>
              </Stack>
            )}
            <Box display="flex" sx={{ justifyContent: "center" }}>
              <Button
                id="submit"
                type="submit"
                disabled={!isFormValid}
                sx={{
                  width: "16rem",
                  px: "4rem",
                  py: "2rem",
                  mx: 4,
                  borderWidth: "2px",
                  border: "solid",
                  borderRadius: "9rem",
                  maxHeight: "3rem",
                }}
              >
                Next
              </Button>
            </Box>
          </Stack>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            {submissionState === SubmissionState.SUBMITTING && (
              <CircularProgress />
            )}
            {submissionState === SubmissionState.FAIL && (
              <Alert severity="error">
                <Typography variant="button">
                  There was a problem saving the report
                </Typography>
              </Alert>
            )}
          </Box>
        </form>
      </PaddedBox>
      <LoginModal
        open={loginModalOpen}
        handleLoginResponse={handleLoginResponse}
        initialFlow={AuthFlow.SIGN_UP}
        handleClose={(_e: any, reason: string) => {
          if (reason === "backdropClick") return;
          setLoginModalOpen(false);
          setSubmissionState(SubmissionState.IDLE);
        }}
      />
      <ReportSavedModal
        open={savedModalOpen}
        handleClose={(_e: any, reason: string) => {
          if (reason === "backdropClick") return;
          setSavedModalOpen(false);
          setSubmissionState(SubmissionState.IDLE);
        }}
      />
    </>
  );
}
