import React from "react";
import { Typography } from "@mui/material";
import QuickDiscreetSimpleSection from "./QuickDiscreetSimpleSection";
import GuideIntroSection from "./GuideIntroSection";
import InstructionsSection from "./InstructionsSection";
import InstructionsWhatsNextSection from "./InstructionsWhatsNextSection";
import { Links } from "../types";
import ButtonLink from "./ButtonLink";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";

export default function InstructionsPage() {
  return (
    <PageContainer>
      <QuickDiscreetSimpleSection />
      <GuideIntroSection />
      <PaddedBox orange>
        <Typography variant="h6">
          Before you start, please read the full guide. We promise it won’t take
          long.
        </Typography>
        <br />
        <Typography variant="h6">
          Wash your hands and set yourself up somewhere comfy with your phone or
          mirror angled so you can see what you’re doing clearly.
        </Typography>
      </PaddedBox>
      <InstructionsSection />
      <InstructionsWhatsNextSection />
      <PaddedBox sx={{ pt: 0 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bolder" }}
          color="secondary.main"
        >
          You’ve already taken a huge first step in your recovery by
          acknowledging what happened.
        </Typography>
        <br />
        <Typography
          variant="h6"
          sx={{ fontWeight: "bolder" }}
          color="secondary.main"
        >
          Have a look at our recovery resources to continue that journey.
        </Typography>
        <br />
        <ButtonLink theme="color-outlined" href={Links.Recovery}>
          Recovery
        </ButtonLink>
      </PaddedBox>
    </PageContainer>
  );
}
