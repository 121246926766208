import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface DeleteModalProps {
  open: boolean;
  title: string;
  description?: string;
  dismissMessage?: string;
  deleteMessage?: string;
  handleDelete: () => void;
  handleClose: () => void;
}

export default function DeleteModal(props: DeleteModalProps) {
  const {
    open,
    title,
    description,
    dismissMessage,
    deleteMessage,
    handleDelete,
    handleClose,
  } = props;
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle variant="h5">{title}</DialogTitle>
      <DialogContentText sx={{ p: 3 }}>{description}</DialogContentText>
      <DialogActions sx={{ justifyContent: "space-around", mb: 5 }}>
        <Button color="success" variant="contained" onClick={handleClose}>
          {dismissMessage}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          {deleteMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
