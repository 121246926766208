import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import LiveWidgetSection from "./LiveWidgetSection";
import ReportNowSection from "./ReportNowSection";

export default function LivePage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <Container>
      <MainMenu />
      <Stack gap={large ? 7 : 4} sx={{ mx: large ? 4 : 0 }}>
        <LiveWidgetSection />
        <ReportNowSection />
      </Stack>
      <Footer />
    </Container>
  );
}
