import React, { useContext, useState } from "react";
import { authContext } from "./AuthProvider";
import { Button } from "@mui/material";
import LoginModal, { AuthFlow } from "./LoginModal";
import { LoginDTO } from "../types";

interface LoginButtonProps {
  sx?: any;
}

export default function LoginButton(props: LoginButtonProps) {
  const { sx } = props;
  const { accessToken, setIdToken } = useContext(authContext);
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => {
    if (accessToken) {
      setIdToken(null);
    } else {
      setModalOpen(!modalOpen);
    }
  };

  const handleLoginResponse = async (dto: LoginDTO) => {
    if (setIdToken) setIdToken(dto);
    setModalOpen(false);
  };

  const buttonStyle = {
    color: "common.black",
    borderWidth: "1px",
    borderRadius: "8px",
    borderColor: "common.black",
    textTransform: "none",
    m: 2,
    height: 0.85,
    fontWeight: 200,
    fontSize: "1.2rem",
    fontFamily: "Recoleta Light",
    ...sx,
  };

  let buttonText = "Sign in";
  if (accessToken) buttonText = "Sign out";

  return (
    <>
      <Button variant="outlined" sx={buttonStyle} onClick={handleButtonClick}>
        {buttonText}
      </Button>
      <LoginModal
        open={modalOpen}
        handleLoginResponse={handleLoginResponse}
        initialFlow={AuthFlow.SIGN_IN}
        handleClose={(_e: any, reason: string) => {
          if (reason === "backdropClick") return;
          setModalOpen(false);
        }}
      />
    </>
  );
}
