import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

interface FAQKitSectionProps {
  title?: string;
}

export default function FAQKitSection(props: FAQKitSectionProps) {
  return (
    <FAQSection title={props.title}>
      <FAQ title="I ordered a kit online. When will it arrive?">
        <Typography variant="body2">
          Kits ordered before 2.00pm Mon-Fri will be posted first class on the
          same day, usually arriving the next day (Tuesdays to Saturdays).
        </Typography>
      </FAQ>

      <FAQ title="What is the kit?">
        <ul style={{ margin: 0 }}>
          <li>
            <Typography sx={{ lineHeight: "2rem" }} variant="body2">
              A DNA kit for you to use discreetly and quickly
            </Typography>
          </li>
          <li>
            <Typography sx={{ lineHeight: "2rem" }} variant="body2">
              A visible signal of the prevalence of rape
            </Typography>
          </li>
          <li>
            <Typography sx={{ lineHeight: "2rem" }} variant="body2">
              A symbol of a world without sexual violence
            </Typography>
          </li>
        </ul>
      </FAQ>

      <FAQ title="Where can I use the kit?">
        <Typography variant="body2">
          You can take a sample from: Vagina, anus, vulva, mouth, skin. The best
          results are from liquid e.g. sperm/saliva, even if now dry.
        </Typography>
      </FAQ>
      <FAQ title="Is the kit for all genders?">
        <Typography variant="body2">
          Yes. The kit is for all genders. Our kit instructions explain how to
          use the swab in different areas.
        </Typography>
      </FAQ>
      <FAQ title="How long do the kits last? ">
        <Typography variant="body2">
          You should get a new one every 4 years. There’s an expiry date on the
          outside of the swab.
        </Typography>
      </FAQ>
      <FAQ title="What does it detect?">
        <Typography variant="body2">
          It shows the presence of someone else’s DNA in or on your body. It
          picks up semen, saliva or persistent touch (sweat). It works even with
          a condom because of persistent touch.
        </Typography>
      </FAQ>
      <FAQ title="What if I’ve showered?">
        <Typography variant="body2">
          You can still use it. Some samples can be taken up to 7 days after.
          Read the user guide for more info.
        </Typography>
      </FAQ>
      <FAQ title="Where should I keep the kit before I use it?">
        <Typography variant="body2">
          Anywhere that will be room temp or below (max 22°C.) Send us pictures
          of where you put it.
        </Typography>
      </FAQ>
      <FAQ title="How is this different to what exists today?">
        <Typography variant="body2">
          You can only be tested if you go to the police or a SARC. 5 in 6
          people don’t want to do that. This is the first time that you can
          self-test and be in complete control of your results and recovery.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
