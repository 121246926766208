import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQOtherSection() {
  return (
    <FAQSection title="Other">
      <FAQ title="Is the university involved?">
        <Typography variant="body2">
          We want to collaborate with universities but believe that first we
          need to show the impact we can have.
        </Typography>
      </FAQ>
      <FAQ title="Can I be an ambassador?">
        <Typography variant="body2">
          You can write in to enough (contact details on website) and have a
          chat with us.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
