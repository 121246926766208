import React, { useContext, useState } from "react";
import { SizeContext } from "./App";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ReportFormSection from "./ReportFormSection";
import WhatNextSection from "./WhatNextSection";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";

export default function ReportPage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  const [showMessage, setShowMessage] = useState(false);
  return (
    <PageContainer>
      <PaddedBox sx={{ pb: 0 }}>
        <Grid2 container>
          <Grid2 xs={large ? 6 : 12} display="flex" alignItems="center">
            <Box>
              <Typography variant="h1" sx={{ mb: 4 }}>
                Reporting is the first step of your recovery
              </Typography>
              <Typography variant="body1">
                Take your time, write what you can and look after yourself. If
                you are considering reporting to the police or a SARC, you
                should go there first.
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </PaddedBox>
      <PaddedBox orange>
        <Typography variant="h3">
          This report belongs to you and no one else. It will be kept in your
          encrypted account
        </Typography>
      </PaddedBox>
      <ReportFormSection onSuccess={() => setShowMessage(true)} />
      {showMessage && <WhatNextSection />}
    </PageContainer>
  );
}
