import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";

interface FundUsIntroSectionProps {
  showLink?: boolean;
}

export default function FundUsIntroSection(props: FundUsIntroSectionProps) {
  return (
    <PaddedBox orange sx={{ textAlign: "center" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography color="common.white" variant="h6">
          We want to create a
        </Typography>
        <Typography color="common.white" variant="h6" sx={{ mb: 3 }}>
          world-changing organization
        </Typography>
        <Typography color="common.white" variant="h6">
          To get started we are raising ‘Impact Loans’
        </Typography>
        {props.showLink && (
          <ButtonLink sx={{ mt: 3 }} href={Links.Fund} theme="color-outlined">
            Learn more
          </ButtonLink>
        )}
      </Box>
    </PaddedBox>
  );
}
