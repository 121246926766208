import React, { useContext } from "react";
import { SizeContext } from "./App";
import { Stack } from "@mui/material";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import { ScrollRestoration } from "react-router-dom";
import FundUsIntroSection from "./FundUsIntroSection";
import FundUsNonProfitSection from "./FundUsNonProfitSection";
import FundUsTermsSection from "./FundUsTermsSection";
import WideContainer from "./WideContainer";

export default function FundUsPage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <WideContainer>
      <MainMenu />
      <Stack gap={large ? 7 : 3}>
        <FundUsIntroSection />
        <FundUsNonProfitSection />
        <FundUsTermsSection />
      </Stack>
      <Footer />
      <ScrollRestoration />
    </WideContainer>
  );
}
