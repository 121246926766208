import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export default function AbuseNumbersSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pt: 0 }}>
      <Stack>
        <Typography variant="h3">
          If you’re experiencing domestic abuse
        </Typography>
        <br />
        <Typography variant="body1">
          You can speak to these services for non-judgmental support and to hear
          about the options available to you. If your safety is threatened in
          any way, always call 999.
        </Typography>
        <br />
        <Grid2 container gap={large ? 0 : 6}>
          <Grid2 xs={large ? 6 : 12}>
            <Stack
              textAlign="center"
              alignItems="center"
              gap={2}
              sx={{ px: large ? 10 : 2 }}
            >
              <Typography fontWeight="bold" variant="body2">
                National Domestic Abuse Helpline
              </Typography>
              <Typography variant="body2">0808 2000 247</Typography>
              <Typography variant="body2" textAlign="center">
                24/7 support via phone or online chat
              </Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Stack
              textAlign="center"
              alignItems="center"
              gap={2}
              sx={{ px: large ? 10 : 2 }}
            >
              <Typography fontWeight="bold" variant="body2">
                Women’s Aid
              </Typography>
              <Typography variant="body2" textAlign="center">
                Live chat, resources, email and community forum
              </Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Stack>
    </PaddedBox>
  );
}
