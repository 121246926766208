import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function ForFriendsSection() {
  return (
    <PaddedBox>
      <Typography variant="h6">For friends...</Typography>
      <ul>
        <li>
          <Typography variant="h6" sx={{ mb: 2 }}>
            You may well have friends who have been through something similar –
            don’t try to relate those experiences. There will be more nuance and
            difference than you expect and it may mean the survivor feels
            unheard. If they ask you to share, that’s completely different
          </Typography>
        </li>
        <li>
          <Typography variant="h6" sx={{ mb: 2 }}>
            You may know the perpetrator, but this conversation isn’t about your
            relationship with them - it’s something that you can process
            privately away from the survivor
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            Don’t be offended if they didn’t initially tell you
          </Typography>
        </li>
      </ul>
    </PaddedBox>
  );
}
