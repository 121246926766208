import * as React from "react";
import Container, { ContainerProps } from "@mui/material/Container";

export default function WideContainer(props: ContainerProps) {
  return (
    <Container
      {...props}
      sx={{
        pl: "0 !important",
        pr: "0 !important",
        px: "0 !important",
        ml: "0 !important",
        mr: "0 !important",
        mx: "0 !important",
        maxWidth: "100% !important",
        ...props.sx,
      }}
    />
  );
}
