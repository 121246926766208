import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function ForSiblingsSection() {
  return (
    <PaddedBox>
      <Typography variant="h6">For siblings...</Typography>
      <ul>
        <li>
          <Typography variant="h6" sx={{ mb: 2 }}>
            They are still your brother or sister, and want to be seen in that
            way. What happened to them hasn’t defined them. One of the most
            impactful things you can do is show them that you continue to see
            them in that way, while also making space to speak about it if and
            when they want to.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            Speak to your parents about this as much as you need to
          </Typography>
        </li>
      </ul>
    </PaddedBox>
  );
}
