import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, StackProps } from "@mui/material";
import { ScrollRestoration } from "react-router-dom";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import WideContainer from "./WideContainer";

interface PageContainerProps extends StackProps {}

export default function PageContainer(props: PageContainerProps) {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <WideContainer>
      <MainMenu />
      <Stack gap={large ? 7 : 4} {...props} />
      <Footer />
      <ScrollRestoration />
    </WideContainer>
  );
}
