import React, { createContext, useState, useEffect } from "react";
import { login } from "../api";
import { LoginDTO } from "../types";

interface AuthContext {
  accessToken: string;
  setIdToken: (idToken: LoginDTO | null) => void;
  previousLogin: null | string;
}

export const authContext = createContext<AuthContext>({
  accessToken: "",
  setIdToken: () => {},
  previousLogin: null,
});

export const AuthProvider = (props: React.PropsWithChildren) => {
  let previousLogin = localStorage.getItem("pli");
  const [accessToken, setAccessToken] = useState<string>("");
  const [idToken, setIdToken] = useState<LoginDTO | null>(null);

  useEffect(() => {
    const getAccessToken = async (idToken: LoginDTO) => {
      try {
        const result = await login(idToken);
        setAccessToken(result.data.accessToken);
        localStorage.setItem("pli", "1");
        previousLogin = "1";
      } catch (e) {
        console.error("Could not set token");
      }
    };
    if (idToken) {
      getAccessToken(idToken);
    } else {
      setAccessToken("");
    }
  }, [idToken]);

  return (
    <authContext.Provider value={{ accessToken, setIdToken, previousLogin }}>
      {props.children}
    </authContext.Provider>
  );
};
