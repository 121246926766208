import React from "react";
import AlliesIntroSection from "./AlliesIntroSection";
import AlliesAdviceSection from "./AlliesAdviceSection";
import AlliesRoleSection from "./AlliesRoleSection";
import RapeMythsSection from "./RapeMythsSection";
import ForParentsSection from "./ForParentsSection";
import ForFriendsSection from "./ForFriendsSection";
import ForPartnersSection from "./ForPartnersSection";
import ForSiblingsSection from "./ForSiblingsSection";
import PageContainer from "./PageContainer";

export default function RecoveryPage() {
  return (
    <PageContainer>
      <AlliesIntroSection />
      <AlliesAdviceSection />
      <AlliesRoleSection />
      <RapeMythsSection />
      <ForParentsSection />
      <ForFriendsSection />
      <ForPartnersSection />
      <ForSiblingsSection />
    </PageContainer>
  );
}
