import React, { createContext, useState, useEffect } from "react";
import { fetchReportStats } from "../api";
import moment from "moment";

export const reportStatsContext = createContext({
  count: 0,
  latest: "days ago",
});

export const ReportStatsProvider = (props: React.PropsWithChildren) => {
  const [reportStats, setReportStats] = useState({
    count: 0,
    latest: "unknown",
  });

  const [refreshed, setRefreshed] = useState(Date.now());

  useEffect(() => {
    const fetchData = async () => {
      let latest = "";
      try {
        const result = await fetchReportStats();
        const { count } = result;
        if (!result.latest) {
          latest = "unknown";
        } else {
          const now = moment();
          const hoursAgo = Math.abs(now.diff(result.latest, "hours"));
          const daysAgo = Math.abs(now.diff(result.latest, "days"));
          const minutesAgo = Math.abs(now.diff(result.latest, "minutes"));
          switch (true) {
            case minutesAgo === 1:
              latest = "1 minute ago";
              break;
            case minutesAgo < 60:
              latest = `${minutesAgo} minutes ago`;
              break;
            case hoursAgo === 1:
              latest = "1 hour ago";
              break;
            case hoursAgo < 24:
              latest = `${hoursAgo} hours ago`;
              break;
            case daysAgo === 1:
              latest = "1 day ago";
              break;
            default:
              latest = `${daysAgo} days ago`;
          }
        }
        setReportStats({ count, latest });
      } catch (e) {
        console.error("Could not fetch stats", e);
      }
    };
    fetchData();
  }, [refreshed]);

  useEffect(() => {
    setInterval(() => setRefreshed(Date.now()), 120000);
  }, []);

  return (
    <reportStatsContext.Provider value={reportStats}>
      {props.children}
    </reportStatsContext.Provider>
  );
};
