import React from "react";
import QuoteCarousel from "./QuoteCarousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";

interface TestimonyCarouselProps extends CarouselProps {}

export default function TestimonyCarousel(props: TestimonyCarouselProps) {
  const quotes = [
    "1 year ago I got ra**d. It’s still extremely hard to say the word or think of it however, I’ve come so far, I’ve managed to heal myself more and not think about it every day.",
    "Asleep is not consent. “I didn’t mean it like that” isn’t a valid excuse.",
    "Although reporting him didn’t get him caught, I felt a sense of relief knowing I could potentially help another victim if there should be one.",
    "It wasn’t a stranger in a dark alley. It was overtly violent like in the movies. It was a colleague who abused his power. This or freezing due to psychological threat does not mean your experience isn’t valid.",
    "People around me said “maybe you just didn’t remember” but you know your gut and your body and if something doesn’t look or feel right, chances are something wasn’t right. Lack of memory does not mean you are in the wrong.",
    "I’m not defined by what you did to me, or by how everyone else defined what you did to me.",
    "It may feel like a stain on your body but stains can be covered up and removed eventually. It may take more work but you can recover and reclaim your life back. It takes time, therapy, and support.",
    "Sometimes I wish he was dead not because of what he did to me but so I would know he couldn’t do it to anyone else",
    "I should not be ashamed. YOU should.",
    "Being in a relationship with them doesn’t make it okay.",
    "I hope he feels the pain one day he put me through. I hope he burns in hell.",
    "just because he didn't ask doesn't mean you consented",
    "Rape is often inaccurately portrayed in film/TV. Rather than being a stranger in an alley of a dimly lit street, it can be someone you know and trust. I froze in the moment as a way of self-preservation to get through the horrible situation. I asked myself why I didn’t fight back if I knew I didn’t want to but maybe I wouldn’t have been so harsh on myself and how I reacted in the situation if I was aware rape didn’t necessarily happen in the way it is portrayed in fictional scenes.",
    "My fear was not your permission",
    "What you did to me was wrong in every sense of the word and I know that now",
    "Like Madame Pelicote said, shame needs to change sides. They knew what they were doing and isolated me to action their plan.",
    "I now know not to blame myself for what he did.",
    "It’s anonymous and literally takes five minutes. It’s the no brainer first response",
    "enough gives you time - something you can never get back later",
    "Coercion is rape. Sometimes you freeze. I froze.",
    "He tried to break me, but I will be stronger than him and that",
    "I’ve turned my doubt into recognition that they wronged me",
    "I hope your Mum knows what you did and who you are",
  ];
  return <QuoteCarousel {...props} quotes={quotes} />;
}
