import React from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import PaddedBox from "./PaddedBox";

import PageContainer from "./PageContainer";

export default function ContactPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <Link to="mailto:hello@myenough.com">
          <Typography variant="h5">hello@myenough.com</Typography>
        </Link>
      </PaddedBox>
    </PageContainer>
  );
}
