import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import YouTubeVideo from "./YouTubeVideo";

export default function AlliesIntroSection() {
  return (
    <PaddedBox orange>
      <YouTubeVideo src="https://www.youtube.com/embed/8ARG3LK0I9Q?si=AiIKt_xkIgqscA3Z" />
      <br />

      <Typography variant="h6" color="common.white">
        You’re likely reading this because something awful has happened to
        someone very close to you.
      </Typography>
      <br />
      <Typography variant="h6" color="common.white">
        It can be really hard for them to talk about what happened – they might
        be worried about how much you already know and what they will have to
        explain, they might fear a negative reaction, and they may not want you
        to associate what happened to them with them. Understandably, survivors
        often just want to forget and move on with their lives. Telling someone
        is an important step in moving forwards, but can feel like turning a
        nightmare into a reality.
      </Typography>
      <br />
      <Typography variant="h6" color="common.white">
        Your reaction is incredibly important – it means more because of your
        closeness, and it will be the start of rebuilding trust that has likely
        been shattered by this experience.
      </Typography>
      <br />
      <Typography variant="h6" color="common.white">
        This conversation is never going to be easy, but we hope the following
        steps mean you can navigate it in a more informed and compassionate way.
      </Typography>
    </PaddedBox>
  );
}
