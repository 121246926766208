import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Box, Typography, Stack } from "@mui/material";

interface FAQSectionProps {
  title?: string;
  children: React.ReactNode;
}

export default function FAQSection(props: FAQSectionProps) {
  const { title, children } = props;
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <Box>
      {title!! && (
        <Typography variant="h2" color="secondary.main">
          {title}
        </Typography>
      )}
      <br />
      <Stack direction={large ? "row" : "column"} flexWrap="wrap">
        {children}
      </Stack>
    </Box>
  );
}
