import React, { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";

interface ChecklistItemProps {
  num?: number;
  title: string;
  subtitle: string;
}

export default function ChecklistItem(props: ChecklistItemProps) {
  const size = useContext(SizeContext);
  const large = size === "large";

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent={large ? "space-between" : "center"}
      flexDirection={large ? "row" : "column"}
      sx={{ mb: large ? 2 : 6 }}
    >
      {props.num && (
        <Typography
          color="secondary"
          fontFamily="Recoleta SemiBold"
          fontSize={large ? "8rem !important" : "6rem !important"}
          sx={{ width: large ? "10%" : "100%" }}
        >
          {props.num}
        </Typography>
      )}
      <Stack sx={{ width: large ? "80%" : "100%" }}>
        <Typography color="secondary" variant="h1" sx={{ mb: 2 }}>
          {props.title}
        </Typography>
        <Typography variant="body2">{props.subtitle}</Typography>
      </Stack>
    </Box>
  );
}
