import React from "react";
import PaddedBox from "./PaddedBox";
import { Typography } from "@mui/material";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

export default function BristolStudentSection() {
  return (
    <PaddedBox>
      <Typography variant="h3">Are you a Bristol student?</Typography>
      <br />
      <ButtonLink theme="outlined" href={Links.Bristol} target="_blank">
        Get a free kit
      </ButtonLink>
    </PaddedBox>
  );
}
