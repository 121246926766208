import React, { useContext, useEffect, useState } from "react";
import { SizeContext } from "./App";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Report } from "../types";
import ReportView from "./ReportView";
import PrintIcon from "@mui/icons-material/Print";

interface ReportListProps {
  reports: Report[];
}

export default function ReportList(props: ReportListProps) {
  const { reports } = props;
  const size = useContext(SizeContext);
  const large = size === "large";
  const sortedReports = [...reports].sort(
    (a: Report, b: Report) =>
      new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
  );

  const handlePrint = () => {
    window.print();
  };

  return (
    <Stack gap={large ? 8 : 4}>
      <Box>
        <Box sx={{ mb: 4 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              className="hide-print"
              variant="h3"
              sx={{ fontWeight: 600, mb: 4 }}
            >
              Your reports
            </Typography>
            <IconButton onClick={handlePrint} className="hide-print">
              <PrintIcon fontSize="large" />
            </IconButton>
          </Stack>
          <Box>
            {sortedReports.length ? (
              sortedReports.map((report, i) => (
                <ReportView num={i + 1} key={report.id} report={report} />
              ))
            ) : (
              <Typography variant="body1">No reports to display</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
