import * as React from "react";
import FindSarcSection from "./FindSarcSection";
import SupportNumbersSection from "./SupportNumbersSection";
import Call999 from "./Call999Section";
import IfWeArentEnoughSection from "./IfWeArentEnoughSection";
import AbuseNumbersSection from "./AbuseNumbersSection";
import UnderSixteenSection from "./UnderSixteenSection";
import PageContainer from "./PageContainer";

export default function NextStepsPage() {
  return (
    <PageContainer>
      <FindSarcSection />
      <SupportNumbersSection />
      <Call999 />
      <AbuseNumbersSection />
      <UnderSixteenSection />
      <IfWeArentEnoughSection />
    </PageContainer>
  );
}
