import * as React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";

export default function SmallTeamsSection() {
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Typography variant="body2">
        Although we encourage and welcome your feedback and interaction, as we
        are a small team we cannot commit to responding to every comment.
        However, this does not mean that we don't see, hear and value your voice
        and engagement. Your comments on our videos and within our community can
        be a valuable part of building a compassionate recovery for others as
        well as yourself.
      </Typography>
    </PaddedBox>
  );
}
