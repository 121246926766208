import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function AlliesRoleSection() {
  return (
    <PaddedBox orange>
      <Typography variant="h4">
        This is going to upset you, but it’s not about you. Try not to project
        your emotions about what happened. Your role is to help them come to a
        realistic judgment of what happened. Think of yourself as their echo.
        It’s their decision, their voice. You are amplifying their voice,
        supporting them so they aren’t alone. You are never shouting anything
        yourself.
      </Typography>
    </PaddedBox>
  );
}
