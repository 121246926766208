import React, { useState } from "react";
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { Typography, Box, Button, Link, Stack, TextField } from "@mui/material";
import {
  signIn,
  signUp,
  confirmSignUp,
  confirmPasswordReset,
} from "../cognito";
import { resetPassword } from "../api";
import { AuthFlow } from "./LoginModal";

interface CognitoLoginFormProps {
  onSuccess: (response: AuthenticationResultType) => void;
  onSignUpSelect: () => void;
  onSignInSelect: () => void;
  onConfirmSelect: () => void;
  onPasswordResetSelect: () => void;
  onPasswordResetConfirmSelect: () => void;
  authFlow: AuthFlow;
}

enum ConfirmedState {
  CONFIRMED,
  PENDING,
  ERROR,
}

export default function CognitoLoginForm(props: CognitoLoginFormProps) {
  const {
    onSignUpSelect,
    onSignInSelect,
    onConfirmSelect,
    onPasswordResetSelect,
    onPasswordResetConfirmSelect,
    authFlow,
  } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmed, setConfirmed] = useState(ConfirmedState.PENDING);
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    switch (authFlow) {
      case AuthFlow.SIGN_IN:
        try {
          const signinResult = await signIn(username, password);
          if (signinResult?.IdToken) {
            props.onSuccess(signinResult);
          }
        } catch (e) {
          if (e && typeof e === "object" && "message" in e) {
            alert(e.message);
          } else {
            alert("Signin failed");
          }
        }
        break;
      case AuthFlow.SIGN_UP:
        if (password !== confirmPassword) {
          alert("Passwords do not match");
          return;
        }
        try {
          await signUp(username, password);
          onConfirmSelect();
        } catch (e) {
          if (e && typeof e === "object" && "message" in e) {
            alert(e.message);
          } else {
            alert("Signup failed");
          }
        }
        break;
      case AuthFlow.CONFIRM:
        const isConfirmed = await confirmSignUp(username, confirmationCode);
        if (isConfirmed) {
          setConfirmed(ConfirmedState.CONFIRMED);
          onSignInSelect();
        } else {
          setConfirmed(ConfirmedState.ERROR);
        }
        break;
      case AuthFlow.PASSWORD_RESET:
        if (username) {
          try {
            await resetPassword({ id: username });
            onPasswordResetConfirmSelect();
          } catch (e) {
            alert("Password reset failed");
          }
        }
        break;
      case AuthFlow.PASSWORD_RESET_CONFIRM:
        if (password !== confirmPassword) {
          alert("Passwords do not match");
          return;
        }
        try {
          await confirmPasswordReset(username, password, confirmationCode);
          setConfirmed(ConfirmedState.CONFIRMED);
          onSignInSelect();
        } catch (e) {
          if (e && typeof e === "object" && "message" in e) {
            alert(e.message);
          } else {
            alert("Password reset failed");
          }
        }
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={1}>
        {confirmed === ConfirmedState.CONFIRMED && (
          <Box>Code confirmed! Log in below.</Box>
        )}
        {authFlow === AuthFlow.CONFIRM &&
          confirmed !== ConfirmedState.CONFIRMED && (
            <Box>Check your inbox for the confirmation code.</Box>
          )}
        <TextField
          id="username"
          size="small"
          onChange={(e) => {
            setUsername(e.currentTarget.value);
          }}
          value={username}
          type="text"
          required
          placeholder="Email"
          inputProps={{
            sx: {
              fontSize: "1.5rem",
            },
          }}
        />

        {(authFlow === AuthFlow.SIGN_UP ||
          authFlow === AuthFlow.SIGN_IN ||
          authFlow === AuthFlow.PASSWORD_RESET_CONFIRM) && (
          <TextField
            id="password"
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            size="small"
            value={password}
            type="password"
            autoComplete="off"
            required
            placeholder="Password"
            inputProps={{
              sx: {
                fontSize: "1.5rem",
              },
            }}
          />
        )}
        {(authFlow === AuthFlow.SIGN_UP ||
          authFlow === AuthFlow.PASSWORD_RESET_CONFIRM) && (
          <TextField
            id="confirmPassword"
            size="small"
            onChange={(e) => {
              setConfirmPassword(e.currentTarget.value);
            }}
            value={confirmPassword}
            type="password"
            autoComplete="off"
            required
            placeholder="Confirm Password"
            inputProps={{
              sx: {
                fontSize: "1.5rem",
              },
            }}
          />
        )}

        {(authFlow === AuthFlow.CONFIRM ||
          authFlow === AuthFlow.PASSWORD_RESET_CONFIRM) && (
          <>
            <TextField
              id="confirmationCode"
              onChange={(e) => {
                setConfirmationCode(e.currentTarget.value);
              }}
              value={confirmationCode}
              size="small"
              type="text"
              autoComplete="off"
              required
              placeholder="Enter confirmation code"
              inputProps={{
                sx: {
                  fontSize: "1.5rem",
                },
              }}
            />
          </>
        )}
        {confirmed === ConfirmedState.ERROR && <Box>Incorrect code</Box>}

        <Button
          color="secondary"
          variant="contained"
          type="submit"
          disabled={
            !username ||
            (authFlow === AuthFlow.SIGN_IN && !password) ||
            (authFlow === AuthFlow.SIGN_UP && !confirmPassword)
          }
        >
          <Typography variant="caption" color="common.white">
            {authFlow === AuthFlow.SIGN_UP ? "Submit and Save" : "Submit"}
          </Typography>
        </Button>

        {authFlow !== AuthFlow.SIGN_IN && (
          <Box display="flex" justifyContent="end">
            <Link component="button" variant="caption" onClick={onSignInSelect}>
              Have an account? Sign in.
            </Link>
          </Box>
        )}
        {authFlow !== AuthFlow.SIGN_UP && (
          <Box display="flex" justifyContent="end">
            <Link component="button" variant="caption" onClick={onSignUpSelect}>
              Need an account? Sign up.
            </Link>
          </Box>
        )}

        {authFlow !== AuthFlow.CONFIRM && (
          <Box display="flex" justifyContent="end">
            <Link
              component="button"
              variant="caption"
              onClick={onConfirmSelect}
            >
              Enter confirmation code
            </Link>
          </Box>
        )}

        {authFlow !== AuthFlow.PASSWORD_RESET && (
          <Box display="flex" justifyContent="end">
            <Link
              component="button"
              variant="caption"
              onClick={onPasswordResetSelect}
            >
              Forgot password?
            </Link>
          </Box>
        )}
      </Stack>
    </form>
  );
}
