import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";

export default function QuickDiscreetSimpleSection() {
  return (
    <PaddedBox orange>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bolder" }}
        color="common.white"
      >
        Quick
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bolder" }}
        color="common.white"
      >
        Discreet
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bolder" }}
        color="common.white"
      >
        Simple
      </Typography>
    </PaddedBox>
  );
}
