import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Box, Typography } from "@mui/material";
import logo from "../img/enough-logo-rgb.png";
import { Link } from "react-router-dom";
import { Links } from "../types";
import ButtonLink from "./ButtonLink";

interface HeaderLogoProps {
  sx?: any;
}

export default function HeaderLogo(props: HeaderLogoProps) {
  const size = useContext(SizeContext);
  const large = size === "large";
  function ExitLink() {
    return (
      <ButtonLink
        theme="clear"
        href="http://google.com"
        sx={{
          p: large ? 4 : 1,
          position: "absolute",
          fontSize: large ? "1.25rem" : "1rem",
          right: large ? "5rem" : 0,
        }}
      >
        Quick Exit
      </ButtonLink>
    );
  }
  return (
    <Box display="flex" sx={{ justifyContent: "center", position: "relative" }}>
      <Link to={Links.Home}>
        <Box
          component="img"
          src={logo}
          sx={{
            width: large ? "13rem" : "10rem",
            mt: 3,
            ...props.sx,
          }}
        />
      </Link>
      <ExitLink />
    </Box>
  );
}
