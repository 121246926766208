import * as React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";

export default function ApproachPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <Typography variant="subtitle2" color="common.black">
          Before you can move on from something like this, you need to
          acknowledge it. There’s something incredibly powerful about
          recognising what happened and finding words for your feelings.
        </Typography>
        <br />
        <Typography variant="body2" color="common.black">
          You aren’t responsible for what happened to you, but you can be
          responsible for your recovery (and make it stronger in doing so!).
          That doesn’t mean you’re alone - far from it. It just means that you
          are in control. You’re at the centre of your recovery and you decide
          what’s enough for you. Tell us what you need - and we’ll try our best
          to create it.
        </Typography>
        <br />
        <Typography variant="body2" color="common.black">
          It’s hard enough going through it yourself, let alone having to
          educate everyone who you want to support you. It might be really scary
          for the people you love, they might say the wrong thing and despite
          their intentions they may not know how to best support you. It might
          feel impossible for you to find the right moment to tell them. We’ve
          created conversation tools, background info, and ongoing guides so
          that you and those you love you have a safe space to navigate this
          conversation.
        </Typography>
        <br />
        <Typography variant="body2" color="common.black">
          Recovery starts with you, but it takes place in the context of
          compassionate and safe relationships. This doesn’t mean you need to
          tell your friends and family, you might feel more comfortable telling
          someone you don’t know who’s been through the same thing. In some
          ways, you can only truly empathise and understand if you’ve been
          through it too, but it can feel impossible to find these people -
          unsurprisingly, they try to bury it. We’ve created a safe online
          community where you can meet others who can bear witness and affirm
          what you’ve been through.
        </Typography>
        <br />
        <Typography variant="body2" color="common.black">
          If you feel ready and you are able to we have created a video
          explaining what to expect from therapy and when it might be right to
          explore.
        </Typography>
        <br />
        <Typography variant="body2" color="common.black">
          It’s not for everyone, but journaling and keeping track of your
          recovery journey can help cement and reaffirm the progress you are
          making, and give you a natural opportunity for reflection.
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
