import React from "react";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SizeContext } from "./App";

export default function TestReportRecoverSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pt: 3, px: 6 }}>
      <Grid2 container>
        <Grid2 xs={large ? 4 : 12} sx={{ pr: 3, pb: large ? 0 : 6 }}>
          <Typography variant="h4" sx={{ pb: 3 }}>
            Self test discreetly.
          </Typography>
          <Typography variant="body2">
            5 minutes, in the privacy of your own home.
          </Typography>
        </Grid2>
        <Grid2 xs={large ? 4 : 12} sx={{ pr: 3, pb: large ? 0 : 6 }}>
          <Typography variant="h4" sx={{ pb: 3 }}>
            Post to the lab.
          </Typography>
          <Typography variant="body2">
            Freepost, special delivery. No backlogs or delays.
          </Typography>
        </Grid2>
        <Grid2 xs={large ? 4 : 12} sx={{ pr: 3, pb: large ? 0 : 6 }}>
          <Typography variant="h4" sx={{ pb: 3 }}>
            See the results.
          </Typography>
          <Typography variant="body2">
            Someone else’s DNA in or on your body.
          </Typography>
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
