import * as React from "react";
import Typography from "@mui/material/Typography";
import PageContainer from "./PageContainer";
import PaddedBox from "./PaddedBox";

export default function NotFoundPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Not Found
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
