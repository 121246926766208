import React, { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import QuoteCarousel from "./QuoteCarousel";

export default function ReportExamplesSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  const quotes = [
    "Coercion is rape. Sometimes you freeze. I froze.",
    "I’ve turned my doubt into recognition that they wronged me",
    "I hope your Mum knows what you did and who you are",
    "He tried to break me, but I will be stronger than him and that",
  ];
  return (
    <Box sx={{ p: 2, pt: 3, backgroundColor: "secondary.main" }}>
      <Typography variant="h5" sx={{ mb: 4 }} color="common.white">
        Here’s some examples...
      </Typography>
      <QuoteCarousel
        height={large ? "4.5rem" : "6rem"}
        indicatorIconButtonProps={{
          style: {
            padding: "10px",
            color: "white",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "black",
          },
        }}
        sx={{ color: "common.white" }}
        quotes={quotes}
      />
    </Box>
  );
}
