import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";

export default function FindSarcSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox orange>
      <Typography variant="h1">Immediately after reporting</Typography>
      <br />
      <Typography variant="body1">
        Seek medical help as soon as possible for any injuries and because you
        may be at risk of pregnancy or sexually transmitted infections (STIs).
      </Typography>
      <br />
      <Typography variant="body1">
        You can access essential sexual health care and emergency contraception
        for free at your local SARC (Sexual Assault Referral Centre), sexual
        health clinic, or local GP.
      </Typography>
      <br />
      <Stack
        direction={large ? "row" : "column"}
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <ButtonLink
          theme="filled"
          href="https://www.nhs.uk/service-search/sexual-health-services/find-a-rape-and-sexual-assault-referral-centre"
          sx={{ width: "20rem" }}
          target="_blank"
        >
          <Typography variant="body1">Find my nearest SARC</Typography>
        </ButtonLink>
        <ButtonLink
          theme="filled"
          href="https://www.nhs.uk/nhs-services/sexual-health-services/find-a-sexual-health-clinic/"
          sx={{ width: large ? "30rem" : "20rem", px: 1 }}
          target="_blank"
        >
          <Typography variant="body1">
            Find my nearest sexual health clinic
          </Typography>
        </ButtonLink>
      </Stack>
    </PaddedBox>
  );
}
