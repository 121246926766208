import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, Typography } from "@mui/material";

interface FAQProps {
  title: string;
  children: React.ReactNode;
}

export default function FAQ(props: FAQProps) {
  const size = useContext(SizeContext);
  const large = size === "large";

  return (
    <Stack
      sx={{
        p: large ? 2 : 0.5,
        mb: large ? 1 : 3,
        mr: large ? 10 : 0,
        width: large ? "25rem" : "100%",
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: large ? 4 : 1 }}>
        {props.title}
      </Typography>
      <>{props.children}</>
    </Stack>
  );
}
