import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import kit from "../img/kit2.png";
import PaddedBox from "./PaddedBox";

export default function ThisIsEnoughSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox orange>
      <Grid2 container>
        <Grid2
          xs={large ? 6 : 12}
          sx={{ zIndex: 2 }}
          display="flex"
          alignItems="center"
        >
          <Box sx={{ pr: 3, pb: large ? 0 : 3 }}>
            <Typography
              color="common.white"
              variant="h1"
              sx={{ mb: 2, letterSpacing: 1.2 }}
            >
              enough <br />
              to end rape
            </Typography>

            <Typography color="common.white" variant="body1">
              A revolutionary form of reporting that deters perpetrators and
              helps survivors recover.
            </Typography>
          </Box>
        </Grid2>
        <Grid2 xs={large ? 6 : 12} display="flex" alignItems="flex-end">
          <Box
            component="img"
            src={kit}
            sx={{ maxWidth: large ? "100%" : "75%" }}
          />
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
