import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Typography, { TypographyProps } from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";

export default function RapeMythsSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  function Myth(props: TypographyProps) {
    return (
      <Grid2 xs={6} sx={{ pr: large ? 4 : 2, lineHeight: "0.5rem" }}>
        <Typography variant={large ? "body1" : "caption"}>
          {props.children}
        </Typography>
      </Grid2>
    );
  }
  function Reality(props: TypographyProps) {
    return (
      <Grid2 xs={6} sx={{ pr: large ? 1 : 0, lineHeight: "0.5rem" }}>
        <Typography variant={large ? "body1" : "caption"}>
          {props.children}
        </Typography>
      </Grid2>
    );
  }
  function Spacer() {
    return <Grid2 xs={12} sx={{ height: "2rem" }} />;
  }
  return (
    <PaddedBox>
      <Typography variant="h4" fontWeight="bold">
        What the world leads us to believe about rape (spoiler: all are false).
      </Typography>
      <br />
      <Typography variant="body2">
        Recovery takes place in the context of societal scripts - culture,
        workplace, education, family and friends. Today, these scripts are false
        and often put the burden of shame on survivors, instead of empowering
        them in their recovery. A more informed and compassionate society means
        we can challenge these myths, and be better allies.
      </Typography>
      <br />
      <Grid2 container>
        <Grid2 xs={6}>
          <Typography variant="body1" fontWeight="bold">
            Myth
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <Typography variant="body1" fontWeight="bold">
            Reality
          </Typography>
        </Grid2>
        <Spacer />
        <Grid2 xs={12} sx={{ height: "2rem" }} />
        <Myth>It’s the survivor’s fault.</Myth>
        <Reality>
          Everyone has the right to wear whatever they want and behave however
          they want without asking for rape or sexual violence.
        </Reality>
        <Spacer />
        <Myth> False accusations are common.</Myth>
        <Reality>
          A man is 230x more likely to be raped himself than falsely accused,
          and it is far more common for survivors to not report (83%) than for
          people to falsely accuse.
          <br />
          <Typography variant="caption">(Independent, 2019)</Typography>
        </Reality>
        <Spacer />
        <Myth>
          If it happened, then they would have reported straight away.
        </Myth>
        <Reality>
          It can take a long time to come to terms with what happened, and
          disclosing is a huge decision. Reporting is not to be taken lightly
          and rarely leads to the justice they want and deserve.
        </Reality>
        <Spacer />
        <Myth> It happens in a dark alley way.</Myth>
        <Reality>
          86% rapes are by someone that the survivor knows.
          <br />
          <Typography variant="caption">(Rape Crisis, 2024)</Typography>
        </Reality>
        <Spacer />
        <Myth> If they didn’t run away, they wanted it to happen</Myth>
        <Reality>
          It’s incredibly common to freeze or not be able to speak – it’s how
          the body reacts to an abnormal and traumatic situation. Watch{" "}
          <Link
            to="https://youtu.be/ExTA0G1pkKs?si=NjvuOvVP7GqpTJex"
            target="_blank"
          >
            Why did I?
          </Link>{" "}
          to learn more.
        </Reality>
      </Grid2>
    </PaddedBox>
  );
}
