import React from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import PaddedBox from "./PaddedBox";

export default function FundUsTermsSection() {
  return (
    <PaddedBox orange>
      <Stack gap={3}>
        <Typography color="common.white" variant="h3" sx={{ mb: 2 }}>
          What are the terms?
        </Typography>
        <Typography color="common.white" variant="body1">
          It’s simple. You loan us a multiple of £5k which we will repay after 5
          years, with 5% interest. If you’d like, you can waive the interest.
        </Typography>
        <Typography color="common.white" variant="body1">
          We need 5 years to reach all UK universities and go international.
        </Typography>
      </Stack>
    </PaddedBox>
  );
}
