import React from "react";
import { Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import YouTubeVideo from "./YouTubeVideo";

export default function IfWeArentEnoughSection() {
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Typography variant="h3">Recover with Maisie</Typography>
      <br />
      <YouTubeVideo src="https://www.youtube.com/embed/7MGfxgRh0gU?si=QMSkXW6Bjr6-wXq7" />
      <br />
      <ButtonLink theme="filled" href={Links.Recovery}>
        See More
      </ButtonLink>
    </PaddedBox>
  );
}
