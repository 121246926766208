import * as React from "react";
import ButtonLink from "./ButtonLink";
import { useLocation } from "react-router-dom";

interface MainMenuButtonProps {
  children: string;
  href: string;
  sx?: any;
}

export default function MainMenuButton(props: MainMenuButtonProps) {
  const { href, children } = props;
  let location = useLocation();
  const { pathname } = location;
  const isSelected = pathname === href;
  return (
    <ButtonLink
      href={href}
      theme="clear"
      sx={{
        "&:hover": {
          borderWidth: 0,
        },
        p: 1.5,
        m: 2,
        width: 0.85,
        fontWeight: isSelected ? 600 : 200,
        fontSize: "1.2rem",
        fontFamily: "Recoleta Light",
        borderWidth: 0,
        ...props.sx,
      }}
    >
      {children}
    </ButtonLink>
  );
}
