import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "2rem",
            borderColor: "#FF6900",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#FF6900",
              color: "#fff",
              borderRadius: "8px",
              borderWidth: "1px",
              borderColor: "#fff",
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#000",
      },
      secondary: {
        main: "#FF6900",
      },
      error: {
        main: red.A400,
      },
    },
    typography: {
      h1: {
        fontFamily: "Avenir Extra Bold, Helvetica, Arial, sans-serif",
        fontSize: "3.25rem",
        // letterSpacing: 1.2,
      },
      h2: {
        fontFamily: "Recoleta SemiBold, serif",
        fontSize: "3.25rem",
        // letterSpacing: 1.2,
      },
      h3: {
        fontFamily: "Avenir Extra Bold, Helvetica, Arial, sans-serif",
        fontSize: "2.75rem",
      },
      h4: {
        fontFamily: "Recoleta SemiBold, serif",
        fontSize: "2.75rem",
      },
      h5: {
        fontFamily: "Avenir Extra Bold, Helvetica, Arial, sans-serif",
        fontSize: "2rem",
      },
      h6: {
        fontFamily: "Recoleta SemiBold, serif",
        fontSize: "2rem",
      },
      subtitle1: {
        fontFamily: "Avenir Extra Bold, Helvetica, Arial, sans-serif",
        fontSize: "1.75rem",
      },
      subtitle2: {
        fontFamily: "Recoleta SemiBold, serif",
        fontSize: "1.75rem",
      },
      body1: {
        fontFamily: "Avenir Roman, Helvetica, Arial, sans-serif",
        fontSize: "2rem",
      },
      body2: {
        fontFamily: "Recoleta Light, serif",
        fontSize: "2rem",
      },
      button: {
        fontFamily: "Avenir Roman, Helvetica, Arial, sans-serif",
      },
      caption: {
        fontFamily: "Avenir Roman, Helvetica, Arial, sans-serif",
        fontSize: "1.25rem",
      },
      overline: {},
    },
  })
);

export default theme;
