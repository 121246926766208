import * as React from "react";
import { Typography } from "@mui/material";
import CallCrisisLineSection from "./CallCrisisLineSection";
import CrisisSupportSection from "./CrisisSupportSection";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import SmallTeamsSection from "./SmallTeamSection";
import PageContainer from "./PageContainer";

export default function CrisisPage() {
  return (
    <PageContainer>
      <CallCrisisLineSection />
      <CrisisSupportSection />
      <PaddedBox>
        <Typography variant="h3" sx={{ pb: 2 }}>
          What else do I need to think about?
        </Typography>

        <Typography variant="body1">
          It can feel really overwhelming, that’s ok. We’ve outlined the most
          important things to consider here:
        </Typography>
        <br />
        <ButtonLink theme="outlined" href={Links.Next}>
          Next steps
        </ButtonLink>
      </PaddedBox>
      <SmallTeamsSection />
    </PageContainer>
  );
}
